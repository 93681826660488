import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./Quotelist.css";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  KotakAPICall,
  digitAPICall,
  getMotorQuote,
  processFlow,
  sompoAPICall,
} from "../services/TPApiCall";
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
  dispatchQuickQuote,
  resetQuickQuoteResults,
} from "../../store/action/userActions";
function QuotelistModel({ show, setModel, handleFilteredResult }) {
  const list = [0, 20, 25, 35, 45, 50];
  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const toggle = () => setModel(!show);
  // const [select, setSelect] = useState(
  //   QuickQouteResult?.length > 0 ? "no" : ""
  // );
  const [select, setSelect] = useState(
    !motorRequest?.isPreviousInsuranceClaimed
      ? "no"
      : motorRequest?.isPreviousInsuranceClaimed
      ? "yes"
      : ""
  );
  const [bonus, setBonus] = useState(motorRequest?.prevNoClaimBonus);
  const [dontKnow, setDontKnow] = useState("");
  const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const dispatch = useDispatch();

  const handleNCB = () => {
    // debugger;
    let newData = { ...apiRequestQQ };
    let newStructureData = { ...motorRequest };
    dispatchQuickQuote("PreviousNoClaimBonus", bonus);
    dispatchMotorQuote("prevNoClaimBonus", bonus);
    const previousClaimData = {
      isPreviousInsuranceClaimed: motorRequest?.isPreviousInsuranceClaimed,
      prevNoClaimBonus: bonus,
    };
    dispatchProcessFlowMotor("step7.previousClaimData", previousClaimData);
    const postData = {
      ...processFlowObjMotor,
      step: "step7",
      motorRequest: { ...motorRequest },
      step7: {
        ...processFlowObjMotor.step7,
        previousClaimData: previousClaimData,
      },
    };
    processFlow(postData).then((response) => console.log("response", response));
    dispatchQuickQuote("NCBSET", true);

    if (select === "no") {
      const PreviousNoClaimBonus = bonus;
      newData = { ...newData, PreviousNoClaimBonus };
      newStructureData = {
        ...newStructureData,
        prevNoClaimBonus: bonus,
      };

      handleFilteredResult(newData, newStructureData);
      // getMotorQuote(newStructureData);
      toggle();
    } else {
      const PreviousNoClaimBonus = 0;
      newData = { ...newData, PreviousNoClaimBonus };
      newStructureData = {
        ...newStructureData,
        prevNoClaimBonus: PreviousNoClaimBonus,
      };
      dispatch(resetQuickQuoteResults());

      // digitAPICall(newData);
      // KotakAPICall(newData);
      handleFilteredResult(newData, newStructureData);
      // sompoAPICall(newData);
      toggle();
    }
  };

  const dontKnowNCB = () => {
    let newData = { ...apiRequestQQ };
    let newStructureData = { ...motorRequest };
    const PreviousNoClaimBonus = 0;

    newData = { ...newData, PreviousNoClaimBonus };
    newStructureData = {
      ...newStructureData,
      prevNoClaimBonus: PreviousNoClaimBonus,
    };

    dispatch(resetQuickQuoteResults());
    // digitAPICall(newData);
    // KotakAPICall(newData);
    // sompoAPICall(newData);
    handleFilteredResult(newData, newStructureData);
    toggle();
  };

  return (
    <Modal isOpen={show} toggle={toggle} backdrop="static" centered size="md">
      <ModalBody>
        <div class="p-2">
          <div class="gsc_modalWrapper">
            <div className="modal-logo">
              <a>
                <img src="/assets/images/insurefastlogos.svg"></img>
              </a>
            </div>
            <div className="modal_heading w-100 mb-3">
              <h4 className="text-center fs-5">Confirm NCB</h4>

              <div onClick={toggle} class="v2_close false">
                <a>
                  <img
                    src="/assets/images/delete_icon.svg"
                    title="Close"
                    alt="close"
                  />
                </a>
              </div>
            </div>

            <div className="mb-3">
              {QuickQouteResult.length === 0 && (
                <div className="mb-3">
                  <h6 class="table bold">
                    Have you made a claim in the previous year?
                  </h6>
                  <div className="d-flex justify-content-around align-items-center confirm-checkbox">
                    <label
                      onClick={() => {
                        setSelect("yes");
                        dispatchQuickQuote("PreviousInsuranceClaimed", true);
                        dispatchMotorQuote("isPreviousInsuranceClaimed", true);
                      }}
                      style={{
                        border:
                          select == "yes"
                            ? "3px solid green"
                            : "1px solid grey",
                        width: "40%",
                        borderRadius: "5px",
                        padding: "5px 0",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      Yes
                    </label>
                    <label
                      onClick={() => {
                        setSelect("no");
                        dispatchQuickQuote("PreviousInsuranceClaimed", false);
                        dispatchMotorQuote("isPreviousInsuranceClaimed", false);
                      }}
                      style={{
                        border:
                          select == "no" ? "3px solid green" : "1px solid grey",
                        width: "40%",
                        borderRadius: "5px",
                        padding: "5px 0",
                      }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      No
                    </label>
                  </div>
                </div>
              )}
              {select == "no" &&
                apiRequestQQ?.NewPolicyType !== "ThirdParty" &&
                apiRequestQQ?.PreviousInsuranceClaimed === false &&
                (apiRequestQQ?.PreviousOwnerChanged === "false" ||
                  apiRequestQQ?.PreviousOwnerChanged === false) && (
                  <div className="">
                    <h6>Select your Existing NCB (No Claim Bonus)</h6>
                    <p>This is mentioned in your previous policy document</p>
                    <div className="ul-percent">
                      {list?.map((res) => {
                        return (
                          <p onClick={() => setBonus(res)}>
                            <label className={bonus == res ? "selected" : ""}>
                              <span>{res}%</span>
                            </label>
                          </p>
                        );
                      })}
                    </div>
                  </div>
                )}
              {/* <label className="mb-2">
                <input
                  type="checkbox"
                  style={{ marginRight: "10px", borderColor: "grey" }}
                />
                I had Zero Dep in my last year policy
              </label> */}
              <div class="ncbmsg">
                {" "}
                <img src="/assets/images/v2_bulbyellow.svg" alt="Idea" /> No
                Claim Bonus (NCB) is a discount offered on Own Damage (OD)
                premium for not claiming on your motor insurance during the
                policy period.
              </div>
            </div>
            <div class="v2_btngrp mb-3">
              <div class="wrapper">
                <button
                  type="submit"
                  onClick={() => handleNCB()}
                  name="submitBtn"
                  class="button submitButton gsc_col-xs-12 gsc_col-md-12"
                >
                  {QuickQouteResult.length > 0 ? "Update Quotes" : "Get Quotes"}
                </button>
              </div>
            </div>
            {select == "no" && (
              <div
                onClick={() => dontKnowNCB()}
                className="d-flex justify-content-center align-items-center"
                style={{ cursor: "pointer" }}
              >
                <p className="text-decoration-underline">I don't Know my NCB</p>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default QuotelistModel;
