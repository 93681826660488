import { select } from "react-cookies";
import {
  API_ERROR_RESPONSE,
  INSPECTION_QUICK_QUOTE,
  LOGGED_USER_DETAILS,
  QUICK_QUOTE_PAYLOAD,
  QUICK_QUOTE_RESULTS,
  REMOVE_ERROR_MESSAGE,
  RESET_QUICK_QUOTE_PAYLOAD,
  RESET_QUICK_QUOTE_RESULTS,
  RESET_SELECTED_PLAN,
  SELECTED_PLAN,
  HEALTH_QUICK_QUOTE_PAYLOAD,
  HEALTH_QUICK_QUOTE_RESULTS,
  RESET_HEALTH_QUICK_QUOTE_PAYLOAD,
  RESET_HEALTH_QUICK_QUOTE_RESULTS,
  RESET_USER_DETAILS,
  USER_DATA,
  RESET_USER_DATA,
  PROCESS_FLOW_MOTOR,
  RESET_PROCESS_FLOW_MOTOR,
  RESET_PROCESS_FLOW_HEALTH,
  PROCESS_FLOW_HEALTH,
  PET_QUICK_QUOTE_PAYLOAD,
  PET_QUICK_QUOTE_RESULT,
  RESET_PET_QUICK_QUOTE_PAYLOAD,
  RESET_PET_QUICK_QUOTE_RESULT,
  MOTOR_QUOTE_PAYLOAD,
  RESET_MOTOR_QUOTE_PAYLOAD,
  KYC_CONFIG,
  RESET_KYC_CONFIG,
} from "../types";
import { POLICY_TYPE } from "../../components/utility/constants";

const initialState = {
  userDetails: {},
  userData: {},
  healthRequestQQ: {
    state: "",
    quotationNumber: "",
    enquiryId: "",
    paymentURL: "",
    CustomerDetails: {
      customerSaluation: "", // either MR or MS
      customerFirstName: "",
      customerMiddleName: "",
      customerLastName: "",
      customerGender: "",
      customerRelation: "",
      customerMobileNumber: "",
      customerPincode: "",
      customerAge: "",
      customerMedicalHistory: {
        NoExistingDesease: false, // it should have blank String or NoDeases
        Diabetes: false,
        DiabetesSince: "",
        hypertensionOrBloodPressure: false,
        hypertensionOrBloodPressureSince: "",
        liverDesease: false,
        liverDeseaseSince: "",
        cancerDeaseas: false,
        cancerDeaseasSince: "",
        cardiacDeaseas: false,
        cardiacDeaseasSince: "",
        jointPain: false,
        jointPainSince: "",
        kidneyDeasease: false,
        kidneyDeaseaseSince: "",
        paralysis: false,
        paralysisSince: "",
        congenitalDeaseas: false,
        congenitalDeaseasSince: "",
        hivAndAidsDeaseas: false,
        hivAndAidsDeaseasSince: "",
        lungDeaseas: false,
        lungDeaseasSince: "",
        endoAndPituitaryDeasease: false,
        endoAndPituitaryDeaseaseSince: "",
        illness: false,
        illnessSince: "",
        surgeries: false,
        surgeriesSince: "",
        otherDeaseas: false,
        otherDeaseasSince: "",
        otherDeaseasDiscription: "",
      },
      customerAdditionalHealthEnquiry: {
        healthHospitalized: false,
        PreviousHealthClaim: false,
        healthDeclined: false,
        healthCovered: false,
        isSmoking: false,
        smokingSince: "",
        smokingDetails: "",
      },
      medicalHistory: {
        Alcohol: false,
        AlcoholDuration: false,
        AlcoholQuantity: false,
        AlcoholType: false,
        Hospitalized: false,
        IllnessOrDisease: false,
        Narcotics: false,
        PED: false,
        Smoker: false,
        SmokerDuration: false,
        SmokerQuantity: false,
        SmokerType: false,
        TakenAnyTreatment: false,
        Tobacco: false,
        TobaccoDuration: false,
        TobaccoQuantity: false,
        TobaccoType: false,
        TreatmentOrMedicine: false,
        VisitedDoctor: false,
      },
      customerDOB: "", // date should be in dd-mm-yyyy format
      customerWeight: "",
      customerHeight: "",
      customerBMI: "",
      customerOccupation: "",
      customerEmailAddress: "",
      customerAddress: {
        permanentAddress: {
          address_1: "",
          address_2: "",
          state: "",
          city: "",
          pincode: "",
          area: "",
        },
        communicationAddress: {
          address_1: "",
          address_2: "",
          state: "",
          city: "",
          pincode: "",
          area: "",
        },
      },
      customerKYCIDType: "",
      customerCity: "",
      customerKYCIDNumber: "",
    },
    NomineeDetails: {
      NomineeName: "",
      NomineeRelation: "",
      NomineeDOB: "",
      NomineeGender: "",
    },
    PlanDetails: {
      sumInsuredValue: "5LAC",
      countOfMembers: "1",
      planTenure: "1year",
      planType: "Individual", // this should be individual or family-floater, if family members are more than 1, then consider it as a family floater
      addons: {
        NCBsuper: false,
        OPDCare: false,
        OPDCareValue: 0,
        smartSelect: false,
        careShield: false,
        co_paymentWaiver: false,
        PEDReduction: false,
        coverageNonMedical: false,
        preExistingDiseaseWaitingPeriod: false,
        maternityChildcareBenefit: false,
      },
    },
  },
  apiRequestQQ: {
    state: "",
    quotationNumber: "",
    enquiryId: "",
    isFiltered: false,
    VehicleType: "",
    CustomerType: "INDIVIDUAL",
    IsPreviousInsurerKnown: true,
    Salutation: "Mr",
    RegistrationDate: "",
    RegistrationNumber: "",
    PolicyEndDate: "",
    PolicyStartDate: "",
    PreInsurerCode: "",
    PreInsurerComp: "",
    PrePolicyNumber: "",
    ProductCode: "",
    ManufaturingDate: "",
    ProposalId: "",
    RegistrationAddress: "",
    CompanyName: "",
    CINNumber: "",
    DateOfIncorporation: "",

    VariantCode: {
      Digit: "",
      Bajaj: "",
      HDFC: "",
      Shriram: "",
      Kotak: "",
      Reliance: "",
      Future: "",
      Royal: "",
      vehicleId: "",
      Liberty: "",
      Sompo: "",
    },
    ApiUniqueNumber: {
      Digit: "",
      Bajaj: "",
      HDFC: "",
      Shriram: "",
      Kotak: "",
      Reliance: "",
      Future: "",
      Royal: "",
      Magma: "",
      Sompo: "",
    },
    Email: "",
    FirstName: "",
    LastName: "",
    MiddleName: "",
    MobileNumber: "",
    FlatNumber: "",
    StreetNumber: "",
    Street: "",
    District: "",
    City: "",
    State: "",
    StateCode: "",
    Country: "",
    Pincode: "",
    PermanentAddress: "",
    Dob: "",
    Gender: "",
    PrePolicyEndDate: "",
    MakeName: "",
    ModelName: "",
    VariantName: "",
    PolicyType: "Renewal",
    RegistrationYear: "",
    PreviousPolicyType: "",
    NewPolicyType: "",
    FuelType: "",
    customerId: "",
    ApiId: "",
    policyId: "",
    RtoCode: "",
    EngineNumber: "",
    ChassisNumber: "",
    NomineeFirstName: "",
    NomineeLastName: "",
    NomineeDateOfBirth: "",
    NomineeRelationship: "",
    NomineeGender: "",
    NomineeMobileNumber: "",
    PreviousInsuranceClaimed: false,
    PreviousOwnerChanged: false,
    Idv: 0,
    PreviousNoClaimBonus: "0",
    CurrentNoClaimBonus: "",
    PersonalAccident: {
      Selection: false,
      InsuredAmount: false,
      CoverTerm: false,
    },
    AddOns: {
      RoadSideAssistance: false,
      EngineProtection: false,
      TyreProtection: false,
      RimProtection: false,
      Consumables: false,
      IsElectricalAccessories: false,
      IsNonElectricalAccessories: false,
      IsCngAccessories: false,
      PersonalAccident: false,
      InvoiceCover: false,
      EngineGearBox: false,
      NCBProtection: false,
      VoluntaryDeductive: false,
      PassengerCover: false,
      LossOfPersonalBelongings: false,
      ZeroDepriciationCover: false,
      KeyReplacement: false,
      LiabilitiesToPaidDriver: false,
      ElectricAmount: 0,
      NonElectricAmount: 0,
      CNGValue: 0,
      PassengerCoverAmount: 0,
      VoluntaryDeductiveAmount: 0,
      discount: 0,
      NoOfLegelDrives: 0,
    },
    PaymentAmount: 0,
    PaymentId: "",
    CubicCapacity: "",
    CarryingCapacity: "",
    VehicleMakeCode: "",
    VehicleModelCode: "",
    VehicleSubTypeCode: "",
    RegistrationCity: "",
    IsVehicleNew: false,
    applicationId: "",
    PolicyStatus: "continue",
    PanNumber: "",
    PanImage: "",
    IsHypothecation: false,
    HpnBank: "",
    HpnCity: "",
    HpnHDFCID: "",
    PreTPPolicyNumber: "",
    PreTPInsurerCode: "",
    PreTPPolicyEndDate: "",
    GSTNo: "",
    IsValidPuc: false,
    PUCNumber: "",
    PUCValidUpto: "",
    RelianceRTOCode: "",
    KGIStatus: false,
    IsInternalCNG: false,
    KYC: {
      HDFC: "",
      Future: "",
      Reliance: "",
      Kotak: "",
      RoyalRefNo: "",
      Royal: "",
      Shriram: "",
      AadharNo: "",
      FatherName: "",
      MotherName: "",
      AadharBackPic: "",
      PassportPic: "",
      Zuno: "",
      ZunoRef: "",
      LibertyRefNo: "",
      Liberty: "",
      Sompo: "",
    },
    NCBSET: false,
  },
  motorRequest: {
    customerLeadId: "",
    customerUniqueNumber: "",
    isEmployeeUser: false,
    rId: "",
    source: "customer",
    startingSource: "",
    emplId: "",
    vehicleType: "",
    vehicleSubType: "",
    newPolicyType: "",
    quotationNumber: "",
    makeName: "",
    makeId: "",
    modelName: "",
    modelId: "",
    variantName: "",
    variantId: "",
    fuelType: "",
    salutation: "",
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    mobileNumber: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    cityId: "",
    stateId: "",
    pincode: "",
    isRcAddressSame: "",
    rcAddressLine1: "",
    rcAddressLine2: "",
    rcAddressLine3: "",
    roadSideAssistance: false,
    engineProtection: false,
    tyreProtection: false,
    consumables: false,
    isElectricalAccessories: false,
    isNonElectricalAccessories: false,
    isCngAccessories: false,
    cngValue: "",
    cngKitType: "",
    isTppdDiscount: false,
    personalAccident: false,
    invoiceCover: false,
    ncbProtection: false,
    isVoluntaryDeductable: false,
    rimProtection: false,
    voluntaryDeductableAmount: "",
    lossOfPersonalBelongings: false,
    zeroDepriciationCover: false,
    keyReplacement: false,
    electricAmount: "",
    nonElectricAmount: "",
    passengerCoverAmount: "",
    isPassengerCover: false,
    liabilitiesToPaidDriver: false,
    noOfPaidDrivers: "",
    isHypothecation: false,
    hpnCity: "",
    hpnBank: "",
    hpnBankCode: "",
    customerType: "individual",
    planId: "",
    isOwnerChanged: false,
    prevPolicyExpiryStatus: "",
    businessType: "",
    insurerCode: "",
    isPreviousInsuranceClaimed: false,
    prevPolicyEndDate: "",
    prevPolicyNumber: "",
    prevNoClaimBonus: 0,
    prevPolicyType: "",
    prevTPInsurerCode: "",
    prevTPPolicyNumber: "",
    prevTPPolicyEndDate: "",
    previousPolicyDetails: "",
    previousTpPolicyDetails: "",
    rtoCode: "",
    manufaturingDate: "",
    registrationDate: "",
    differenceInYears: "",
    registrationNumber: "",
    variantCode: "",
    idv: 0,
    engineNumber: "",
    chassisNumber: "",
    isValidPuc: false,
    pucNumber: "",
    pucValidUpto: "",
    nomineeName: "",
    nomineeDob: "",
    nomineeRelation: "",
    appointeeName: "",
    appointeeDob: "",
    appointeeRelation: "",
    reasonForNotOptingPersonalAccident: "",
    caseId: "",
    kycStatus: "",
    kycType: "",
    dob: "",
    gender: "",
    maritalStatus: "",
    docName: "",
    docNumber: "",
    city: "",
    state: "",
    kycData: {},
    isVehicleNew: false,
    handlePreviousButton: false,
    rtoCityName: "",
    isPrevZeroDepreciationCover: true,
    isPrevInvoiceCover: true,
    isPrevEngineProtection: true,
    isPrevTyreProtection: true,
    reasonForNotOptingCpa: "",
    updatedFutureQuotes: false,
    journeyFromStart: false,
    hpnBankData: {},
  },
  petRequestQQ: {
    uniqueId: "",
    quotationNumber: "INSFT000126",
    userDetails: {
      ownerFullName: "", // apply validations (first+middle+last)name
      ownerEmail: "", // apply validations
      ownerNumber: "", // apply validations
      ownerDOB: "",
      ownerGender: "", // male, female & other will be in request,
      ownerAddress1: "",
      ownerAddress2: "",
      ownerAddress3: "",
      pincode: "",
      state: "",
      city: "",
      panNumber: "",
      ownerOccupation: "",
      ownerMaritalStatus: "",
    },
    petDetails: [
      {
        dogBreed: "",
        dogName: "",
        ageOfDog: {
          ageInYears: "",
          ageInMonth: "",
        },
        questionOfDogED: false,
        dogWeight: "",
        gender: "", // male and female only two options will be visible
        identification: "",
        features: "",
        petTagNo: "",
        petValue: "",
        gogreen: false, // Gogreen - Y or N – Do you want to go green
        vaterinary: false, // Veterinary - Y or N  - access to a Veterinary Doctor?
        declInsurance: false, // "DecIns 	Y or N -  Declined insurance of any of your pet dogs? "
        declRenew: false, // "DecRen 	Y or N - Declined to renew the insurance? "
        IncPremium: false, //"IncPrem 	Y or N - increased your premium "
        question1: true, // "Q1Answer 	 Is/are your pet dog(s) sound and healthy? "
        question2: "", // "Q2Answer 	 Full particulars of defects and aliments (If No for Q1Answer)" **this field will have free text field**
        question3: false, //"Q3Answer 	Any Pre-existing disease/conditions Pet Dog(s) suffers? "
        question4: "", //"Q4Answer 	Full particulars of disease and conditions (If Yes for Q3Answer) " **this field will have free text field**
        question5: "", //"Q5Answer 	vaccinations provided( Separated by Coma) " **this field will have free text field**
        question6: false, //"Q6Answer 	used for commercial purpose? "
        question7: "", //"Q7Answer 	Details for commercial purpose (if yes for Q6Answer) " **this field will have free text field**
        question8: false, //"Q8Answer 	Insure Elsewhere? "

        // below two fields will be asked if q8 answer is true
        question9: "", //"Q9Answer 	Policy Number(if Insured for Q8Answer) "
        question10: "", //"Q10Answer 	 Name of Insurance Company (if Insured for Q8Answer) "
        photo1: "",
        photo2: "",
        photo3: "",
        photo4: "",
        photo5: "",
        document: "",
      },
    ],

    planDetails: {
      dogSI: "",
      dogCount: 1,
    },
    addons: {
      funeralCost: false, // this will be boolean value
      TerminalDeaseas: false, // this will be boolean value
      vetOncall: {
        planSelected: false, // boolean
        visits: "", // dropdown of 5visits and 10visits
        amountPerVisit: "", // dropdown of 1000 and 2000
      },
      lostAndStolen: false, // boolean
    },
    kycDetails: {
      kycIdType: "", // aadharNum, pan, ckyc, cin, voterId, DrivingLicence, passport
      idNumber: "",
    },
  },
  processFlowObjMotor: {
    loggedInUser: false,
    quotationNumber: "",
    step: "",
    step1: {
      registerationNumber: "",
    },
    step2: {
      make: "",
    },
    step3: {
      model: "",
    },
    step4: {
      variantSelection: "",
    },
    step5: {
      customerDetails: "",
    },
    step6: {
      pypSelection: "",
    },
    step7: {},
    step8: {
      kycAndUserDetails: "",
      planDetails: "",
    },
    step9: {
      nomineeDetails: "",
      planDetails: "",
    },
    step10: {
      vehicleDetails: "",
      planDetails: "",
    },
    step11: {},
    step12: {},
    step13: {},
    customerDetails: {
      FullName: "",
      Gender: "",
      Email: "",
      Phone: "",
      DateofBirth: "",
      MaritalStatus: "",
      City: "",
      CustomerUniqueNumber: "",
    },
  },
  processFlowObjHealth: {
    loggedInUser: false,
    quotationNumber: "",
    step: "",
    step1: {},
    step2: {},
    step3: {},
    step4: {},
    step5: {},
    step6: {},
    step7: {},
    step8: {},
    step9: {},
    step10: {},
    step11: {},
    step12: {},
    step13: {},
    customerDetails: {
      FullName: "",
      Gender: "",
      Email: "",
      Phone: "",
      DateofBirth: "",
      MaritalStatus: "",
      City: "",
      CustomerUniqueNumber: "",
    },
  },
  QuickQouteResult: [],
  HealthQuickQuoteResult: [],
  PetQuickQuoteResult: [],
  kycConfig: {},
  selectedPlan: {},
  ApiErrorArr: [],
};
const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case LOGGED_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...action.payload,
        },
      };
    case USER_DATA:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload,
        },
      };
    case QUICK_QUOTE_PAYLOAD:
      const { key, value } = action.payload;
      let obj = { ...state.apiRequestQQ };
      if (key.includes(".")) {
        let reqKey = key.split(".");
        obj[reqKey[0]][reqKey[1]] = value;
      } else {
        obj[key] = value;
      }
      return {
        ...state,
        apiRequestQQ: obj,
      };
    case PROCESS_FLOW_MOTOR:
      const { key: processFlowKey, value: processFlowValue } = action.payload;
      let processFlowObjMotorj = { ...state.processFlowObjMotor };
      if (processFlowKey.includes(".")) {
        let reqKey = processFlowKey.split(".");
        processFlowObjMotorj[reqKey[0]][reqKey[1]] = processFlowValue;
      } else {
        processFlowObjMotorj[processFlowKey] = processFlowValue;
      }
      return {
        ...state,
        processFlowObjMotor: processFlowObjMotorj,
      };
    case HEALTH_QUICK_QUOTE_PAYLOAD:
      const { key: healthKey, value: healthValue } = action.payload;

      let healthObj = { ...state.healthRequestQQ };
      const healthKeyParts = healthKey.split(".");

      function updateNestedObjectHealth(obj, healthKeyParts, healthValue) {
        if (healthKeyParts.length === 1) {
          obj[healthKeyParts[0]] = healthValue;
          return obj;
        }
        const currentKeyPart = healthKeyParts[0];
        if (!obj[currentKeyPart]) {
          obj[currentKeyPart] = {};
        }
        obj[currentKeyPart] = updateNestedObjectHealth(
          obj[currentKeyPart],
          healthKeyParts.slice(1),
          healthValue
        );
        return obj;
      }

      healthObj = updateNestedObjectHealth(
        healthObj,
        healthKeyParts,
        healthValue
      );

      return {
        ...state,
        healthRequestQQ: healthObj,
      };

    case MOTOR_QUOTE_PAYLOAD:
      const { key: motorQuoteKey, value: motorQuoteValue } = action.payload;

      let motorQuoteObject = { ...state.motorRequest };
      const motorQuoteKeyParts = motorQuoteKey.split(".");

      function updateNestedMotorObject(
        obj,
        motorQuoteKeyParts,
        motorQuoteValue
      ) {
        if (motorQuoteKeyParts.length === 1) {
          obj[motorQuoteKeyParts[0]] = motorQuoteValue;
          return obj;
        }
        const currentMotorKeyPart = motorQuoteKeyParts[0];
        if (!obj[currentMotorKeyPart]) {
          obj[currentMotorKeyPart] = {};
        }
        obj[currentMotorKeyPart] = updateNestedMotorObject(
          obj[currentMotorKeyPart],
          motorQuoteKeyParts.slice(1),
          motorQuoteValue
        );
        return obj;
      }

      motorQuoteObject = updateNestedMotorObject(
        motorQuoteObject,
        motorQuoteKeyParts,
        motorQuoteValue
      );

      return {
        ...state,
        motorRequest: motorQuoteObject,
      };
    case PET_QUICK_QUOTE_PAYLOAD:
      const { key: petKey, value: petValue } = action.payload;

      let petObj = { ...state.petRequestQQ };
      const petKeyParts = petKey.split(".");

      function updateNestedObjectPet(obj, petKeyParts, petValue) {
        if (petKeyParts.length === 1) {
          obj[petKeyParts[0]] = petValue;
          return obj;
        }
        const currentKeyPart = petKeyParts[0];
        if (!obj[currentKeyPart]) {
          obj[currentKeyPart] = {};
        }
        obj[currentKeyPart] = updateNestedObjectPet(
          obj[currentKeyPart],
          petKeyParts.slice(1),
          petValue
        );
        return obj;
      }

      if (petKey.startsWith("petDetails")) {
        // Extract petIndex from the petKey
        const [arrayKey, index] = petKey.split(/[\[\]]/).filter(Boolean);

        // Update the corresponding pet detail
        petObj.petDetails[petIndex] = {
          ...petObj.petDetails[petIndex],
          [petKey.split(".").slice(2).join(".")]: petValue,
        };
      } else {
        petObj = updateNestedObjectPet(petObj, petKeyParts, petValue);
      }

      return {
        ...state,
        petRequestQQ: petObj,
      };

    case PROCESS_FLOW_HEALTH:
      const { key: processFlowHealthKey, value: processFlowHealthValue } =
        action.payload;

      let processFlowHealthObj = { ...state.processFlowObjHealth };
      const processFlowHealthKeyParts = processFlowHealthKey.split(".");

      function updateNestedObject(
        obj,
        processFlowHealthKeyParts,
        processFlowHealthValue
      ) {
        if (processFlowHealthKeyParts.length === 1) {
          obj[processFlowHealthKeyParts[0]] = processFlowHealthValue;
          return obj;
        }
        const currentKeyPart = processFlowHealthKeyParts[0];
        if (!obj[currentKeyPart]) {
          obj[currentKeyPart] = {};
        }
        obj[currentKeyPart] = updateNestedObject(
          obj[currentKeyPart],
          processFlowHealthKeyParts.slice(1),
          processFlowHealthValue
        );
        return obj;
      }

      processFlowHealthObj = updateNestedObject(
        processFlowHealthObj,
        processFlowHealthKeyParts,
        processFlowHealthValue
      );

      return {
        ...state,
        processFlowObjHealth: processFlowHealthObj,
      };
    case QUICK_QUOTE_RESULTS:
      let data = [...state.QuickQouteResult];
      let index = data.findIndex(
        (item) => item.insurer === action.payload.insurer
      );
      if (index > -1) {
        data[index] = action.payload;
      } else {
        data.push(action.payload);
      }
      return {
        ...state,
        QuickQouteResult: data,
      };
    case HEALTH_QUICK_QUOTE_RESULTS:
      let healthData = { ...state.HealthQuickQuoteResult };
      healthData = action.payload;
      // let healthIndex = healthData.findIndex(
      //   (item) => item.insurer === action.payload.insurer
      // );
      // if (healthIndex > -1) {
      //   healthData[healthIndex] = action.payload;
      // } else {
      //   healthData.push(action.payload);
      // }
      return {
        ...state,
        HealthQuickQuoteResult: healthData,
      };
    case PET_QUICK_QUOTE_RESULT:
      let petData = { ...state.PetQuickQuoteResult };
      petData = action.payload;
      return {
        ...state,
        PetQuickQuoteResult: petData,
      };
    case KYC_CONFIG:
      return {
        ...state,
        kycConfig: action.payload,
      };
    case REMOVE_ERROR_MESSAGE:
      let data2 = [...state.ApiErrorArr];
      let index2 = data2.findIndex((item) => item.Api_name === action.payload);
      data2.splice(index2, 1);
      return {
        ...state,
        ApiErrorArr: data2,
      };
    case API_ERROR_RESPONSE:
      let data1 = [...state.ApiErrorArr];
      let index1 = data1.findIndex(
        (item) => item.Api_name === action.payload.Api_name
      );
      if (index1 > -1) {
        data1[index1] = action.payload;
      } else {
        data1.push(action.payload);
      }
      return {
        ...state,
        ApiErrorArr: data1,
      };
    case SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case RESET_QUICK_QUOTE_PAYLOAD:
      return {
        ...state,
        apiRequestQQ: action.payload,
      };
    case RESET_MOTOR_QUOTE_PAYLOAD:
      return {
        ...state,
        motorRequest: action.payload,
      };
    case RESET_PROCESS_FLOW_MOTOR:
      return {
        ...state,
        processFlowObjMotor: action.payload,
      };
    case RESET_PROCESS_FLOW_HEALTH:
      return {
        ...state,
        processFlowObjHealth: action.payload,
      };
    case RESET_HEALTH_QUICK_QUOTE_PAYLOAD:
      return {
        ...state,
        healthRequestQQ: action.payload,
      };
    case RESET_PET_QUICK_QUOTE_PAYLOAD:
      return {
        ...state,
        petRequestQQ: action.payload,
      };
    case INSPECTION_QUICK_QUOTE:
      return {
        ...state,
        apiRequestQQ: action.payload,
      };
    case RESET_QUICK_QUOTE_RESULTS:
      return {
        ...state,
        QuickQouteResult: [],
        ApiErrorArr: [],
      };
    case RESET_HEALTH_QUICK_QUOTE_RESULTS:
      return {
        ...state,
        HealthQuickQuoteResult: [],
        ApiErrorArr: [],
      };
    case RESET_PET_QUICK_QUOTE_RESULT:
      return {
        ...state,
        PetQuickQuoteResult: [],
        ApiErrorArr: [],
      };
    case RESET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: {},
      };
    case RESET_USER_DETAILS:
      return {
        ...state,
        userDetails: {},
      };
    case RESET_USER_DATA:
      return {
        ...state,
        userData: {},
      };
    case RESET_KYC_CONFIG:
      return {
        ...state,
        kycConfig: {},
      };
    default:
      return state;
  }
};
export default userReducers;

export const quickQuotePayloadObj = (obj) => {
  return {
    BrokerId: "SARL",
    CompanyCode: "DIGIT",
    VehicleType: obj.VehicleType,
    NewPolicyType: obj.NewPolicyType,
    UniqueId: makeid(24),
    quotationNumber: obj.quotationNumber,
    ApiUniqueNumber: obj.ApiUniqueNumber,
    Contactdetails: {
      Salutation: obj.Salutation,
      Email: obj.Email,
      FirstName: obj.FirstName,
      LastName: obj.LastName,
      MiddleName: obj.MiddleName,
      MobileNumber: obj.MobileNumber,
      OTPNumber: "20359",
      SubProductID: 2,
      FlatNumber: obj.FlatNumber,
      StreetNumber: obj.StreetNumber,
      Street: obj.Street,
      District: obj.District,
      StateCode: obj.StateCode,
      State: obj.State,
      City: obj.City,
      Country: obj.Country,
      Pincode: obj.Pincode,
      PermanentAddress: obj.PermanentAddress,
      Dob: obj.Dob,
      Gender: obj.Gender && obj.Gender.toUpperCase(),
      PanNumber: obj.PanNumber,
      PanImage: obj.PanImage,
      GSTNo: obj.GSTNo,
    },
    AddOns: {
      RoadSideAssistance: obj.AddOns.RoadSideAssistance,
      EngineProtection: obj.AddOns.EngineProtection,
      TyreProtection: obj.AddOns.TyreProtection,
      RimProtection: obj.AddOns.RimProtection,
      Consumables: obj.AddOns.Consumables,
      IsElectricalAccessories: obj.AddOns.IsElectricalAccessories,
      IsNonElectricalAccessories: obj.AddOns.IsNonElectricalAccessories,
      IsCngAccessories: obj.AddOns.IsCngAccessories,
      PersonalAccident: obj.AddOns.PersonalAccident,
      InvoiceCover: obj.AddOns.InvoiceCover,
      EngineGearBox: obj.AddOns.EngineGearBox,
      NCBProtection: obj.AddOns.NCBProtection,
      VoluntaryDeductive: obj.AddOns.VoluntaryDeductive,
      PassengerCover: obj.AddOns.PassengerCover,
      LossOfPersonalBelongings: obj.AddOns.LossOfPersonalBelongings,
      ZeroDepriciationCover: obj.AddOns.ZeroDepriciationCover,
      KeyReplacement: obj.AddOns.KeyReplacement,
      ElectricAmount:
        obj.AddOns.IsElectricalAccessories == true
          ? obj.AddOns.ElectricAmount
          : 0,
      NonElectricAmount:
        obj.AddOns.IsNonElectricalAccessories == true
          ? obj.AddOns.NonElectricAmount
          : 0,
      VoluntaryDeductiveAmount:
        obj.AddOns.VoluntaryDeductive == true
          ? obj.AddOns.VoluntaryDeductiveAmount
          : 0,
      PassengerCoverAmount:
        obj.AddOns.PassengerCover === true
          ? obj.AddOns.PassengerCoverAmount
          : 0,
      CNGValue: obj.AddOns.CNGValue,
      discount: obj.AddOns.discount,
      LiabilitiesToPaidDriver: obj.AddOns.LiabilitiesToPaidDriver,
      NoOfLegelDrives: obj.AddOns.NoOfLegelDrives,
    },
    Hypothecation: {
      IsHypothecation: obj.IsHypothecation,
      HpnCity: obj.HpnCity,
      HpnBank: obj.HpnBank,
      HDFC: obj.HpnHDFCID,
    },
    CustomerType: obj.CustomerType,
    DeviceId: "Web",

    IsPreviousInsurerKnown: obj.IsPreviousInsurerKnown,
    IsBreakingCase:
      obj.PolicyStatus == "expired within 90 day" ||
      obj.PolicyStatus == "expired above 90 day"
        ? true
        : false,
    IsExistingPACover: false,
    IsODOnly: false,
    IsOwnerChanged: false,
    IsPOSIncluded: false,
    IsRecalculateQuote: false,
    IsThirdPartyOnly: false,
    IsValidLicence: true,
    LoginUserId: 0,
    MakeName: obj.MakeName,
    ModelName: obj.ModelName,
    NewBusinessPolicyType: "0",
    OrganizationName: null,
    PrevPolicyExpiryStatus: obj.PolicyStatus,
    PlanId: 74,
    PolicyEndDate: obj.PolicyEndDate,
    PolicyStartDate: obj.PolicyStartDate,
    PolicyType: "Renewal",
    PreviousPolicyDetails: {
      InsurerCode: obj.PreInsurerCode,
      InsurerCompany: obj.PreInsurerComp,
      IsPreviousInsuranceClaimed: obj.PreviousInsuranceClaimed,
      IsPreviousOwnerChanged: obj.PreviousOwnerChanged,
      PolicyEndDate: obj.PrePolicyEndDate,
      PolicyNumber: obj.PrePolicyNumber,
      PreviousNoClaimBonus: obj.PreviousNoClaimBonus,
      CurrentNoClaimBonus: obj.CurrentNoClaimBonus,
      PreviousPolicyType: obj.PreviousPolicyType,
    },
    PreviousTpPolicyDetails: {
      PreTPInsurerCode: obj.PreTPInsurerCode,
      PreTPPolicyNumber: obj.PreTPPolicyNumber,
      PreTPPolicyEndDate: obj.PreTPPolicyEndDate,
    },
    PreviousPolicyDetailsRequired: true,
    ProductCode: obj.ProductCode,
    QuotationNumber: "SARLMTRPC2022071516433185",
    RTOCityName: obj.RtoCode,
    RequestedAddOnList: [],
    SubProductCode: 2,
    Uid: "3292d524-b60d-4c14-aff7-1e07d096af4a",
    VariantName: obj.VariantName,
    VehicleDetails: {
      IsVehicleNew: obj.IsVehicleNew,
      IsInternalCNG: obj.IsInternalCNG,
      BPRtoId: "976",
      BimaPostRTOId: "976",
      ManufaturingDate: obj.ManufaturingDate,
      PurchaseDate: "2014-04-01",
      RegistrationDate: obj.RegistrationDate,
      RegistrationNumber: obj.RegistrationNumber,
      VariantCode: {
        Bajaj: obj.VariantCode.Bajaj,
        Digit: obj.VariantCode.Digit,
        HDFC: obj.VariantCode.HDFC,
        Shriram: obj.VariantCode.Shriram,
        Kotak: obj.VariantCode.Kotak,
        Reliance: parseInt(obj.VariantCode.Reliance),
        Future: obj.VariantCode.Future,
        Royal: obj.VariantCode.Royal,
        Liberty: obj.VariantCode.Liberty,
        Sompo: obj.VariantCode.Sompo,
      },

      RegistrationYear: obj.RegistrationYear,
      Idv: obj.Idv,
      EngineNumber: obj.EngineNumber,
      ChassisNumber: obj.ChassisNumber,
      VehicleMakeCode: obj.VehicleMakeCode,
      VehicleModelCode: obj.VehicleModelCode,
      VehicleSubTypeCode: obj.VehicleSubTypeCode,
      CarryingCapacity: obj.CarryingCapacity,
      CubicCapacity: obj.CubicCapacity,
      Color: obj.Color,
      NCB: "0",
      RegistrationCity: obj.RegistrationCity,
      MakeName: obj.MakeName,
      ModelName: obj.ModelName,
      VariantName: obj.VariantName,
      FuelType: obj.FuelType,
      IsValidPuc: obj.IsValidPuc,
      PUCNumber: obj.PUCNumber,
      PUCValidUpto: obj.PUCValidUpto,
    },
  };
};

// New Structure

export const motorQuotationObj = (obj) => {
  return {
    customerLeadId: obj?.customerLeadId,
    customerId: obj?.customerUniqueNumber,
    rId: obj?.rId,
    source: obj?.source,
    employeeId: obj?.emplId,
    vehicleType: obj?.vehicleType,
    vehicleSubType: "",
    newPolicyType: obj?.newPolicyType,
    quotationNumber: "",
    roadSideAssistance: obj?.roadSideAssistance,
    engineProtection: obj?.engineProtection,
    tyreProtection: obj?.tyreProtection,
    consumables: obj?.consumables,
    isElectricalAccessories: obj?.isElectricalAccessories,
    isNonElectricalAccessories: obj?.isNonElectricalAccessories,
    isCngAccessories: obj?.isCngAccessories,
    cngValue: obj?.cngValue,
    cngKitType: obj?.cngKitType, // externallyFitted/companyFitted
    isTppdDiscount: obj?.isTppdDiscount,
    personalAccident: obj?.personalAccident,
    invoiceCover: obj?.invoiceCover,
    ncbProtection: obj?.ncbProtection,
    isVoluntaryDeductable: obj?.isVoluntaryDeductable,
    voluntaryDeductableAmount: obj?.voluntaryDeductableAmount,
    lossOfPersonalBelongings: obj?.lossOfPersonalBelongings,
    zeroDepriciationCover: obj?.zeroDepriciationCover,
    keyReplacement: obj?.keyReplacement,
    electricAmount: obj?.electricAmount,
    nonElectricAmount: obj?.nonElectricAmount,
    passengerCoverAmount: obj?.passengerCoverAmount,
    isPassengerCover: obj?.isPassengerCover,
    liabilitiesToPaidDriver: obj?.liabilitiesToPaidDriver,
    rimProtection: obj?.rimProtection,
    noOfPaidDrivers: obj?.noOfPaidDrivers,
    customerType: obj?.customerType,
    isOwnerChanged: obj?.isOwnerChanged,
    businessType: obj?.businessType,
    isPreviousInsuranceClaimed: obj?.isPreviousInsuranceClaimed,
    prevPolicyEndDate: obj?.prevPolicyEndDate,
    prevNoClaimBonus: obj?.prevNoClaimBonus,
    prevPolicyType: obj?.prevPolicyType,
    rtoCode: obj?.rtoCode,
    manufaturingDate: obj?.manufaturingDate,
    registrationDate: obj?.registrationDate,
    registrationNumber: obj?.registrationNumber,
    variantCode: obj?.variantId,
    idv: obj?.idv,
    prevInsurerCode: obj?.insurerCode,
    sessionId: obj?.sessionId || "",
    isPrevZeroDepreciationCover: obj?.isPrevZeroDepreciationCover,
    isPrevInvoiceCover: obj?.isPrevInvoiceCover,
    isPrevEngineProtection: obj?.isPrevEngineProtection,
    isPrevTyreProtection: obj?.isPrevTyreProtection,
  };
};

export const kycObj = (obj, selectedPlan, kycFields) => {
  console.log("kycFields", kycFields);
  console.log("kycFields obj", obj);
  const reqFields = kycFields.filter(
    (field) => field?.docName === obj?.docName
  )?.[0].requiredField;
  console.log("reqFields", reqFields);
  let kycDocData = reqFields.map((field) => {
    console.log("field", field, obj[field]);
    return {
      kycDoc: field === "documentNo" ? "docNumber" : field,
      kycDocValue: field === "documentNo" ? obj?.docNumber : obj[field],
      kycDocLink: "",
    };
  });

  console.log("kycDocData", kycDocData);
  return {
    caseId: selectedPlan?.caseId,
    customerLeadId: obj?.customerLeadId,
    customerId: obj?.customerUniqueNumber,
    source: "customer",
    kycType: obj?.kycType,
    insurerId: selectedPlan?.insurerId,
    productType: obj?.vehicleType,
    customerType: obj?.customerType,
    kycMainDocType: obj?.docName,
    kycMainDocValue: obj?.docNumber,
    kycMainDocLink: "",
    kycDocData: kycDocData,
    // [
    //   {
    //     kycDoc: "firstName",
    //     kycDocValue: obj?.firstName,
    //     // obj?.middleName
    //     //   ? `${obj?.firstName} ${obj?.middleName} ${obj?.lastName}`
    //     //   : `${obj?.firstName} ${obj?.lastName}`,
    //     kycDocLink: "",
    //   },
    //   // {
    //   //   kycDoc: "lastName",
    //   //   kycDocValue: obj?.lastName,
    //   //   // obj?.middleName
    //   //   //   ? `${obj?.firstName} ${obj?.middleName} ${obj?.lastName}`
    //   //   //   : `${obj?.firstName} ${obj?.lastName}`,
    //   //   kycDocLink: "",
    //   // },
    //   {
    //     kycDoc: "gender",
    //     kycDocValue: obj?.gender,
    //     kycDocLink: "",
    //   },
    //   {
    //     kycDoc: "dob",
    //     kycDocValue: obj?.dob,
    //     kycDocLink: "",
    //   },
    // ],
    kycAdditionalData: selectedPlan?.kycAdditionalData,
  };
};

export const kycStatus = (obj, planDetail) => {
  return {
    caseId: planDetail?.caseId,
    source: "customer",
    insurerId: planDetail?.insurerId,
    productType: obj?.vehicleType,
    customerType: obj?.customerType,
  };
};

// export const sendEmailRequest = (data, motorRequest) => {
//   return {
//     emailId: motorRequest?.email,
//     caseId: motorRequest?.caseId,
//     customerLeadId: motorRequest?.customerLeadId,
//     mailType: data,
//     customerName: motorRequest?.firstName + " " + motorRequest?.lastName,
//   };
// };

// export const sendSmsRequest = (data, motorRequest) => {
//   return {
//     mobileNo: motorRequest?.mobileNumber,
//     caseId: motorRequest?.caseId,
//     customerLeadId: motorRequest?.customerLeadId,
//     smsType: data,
//     customerName: motorRequest?.firstName + " " + motorRequest?.lastName,
//   };
// };

// Proposal REquest

export const submitProposalRequest = (obj, selectedPlan, kycFields) => {
  console.log("kycFields", kycFields);
  const newKYCData = kycObj(obj, selectedPlan, kycFields);
  return {
    customerLeadId: obj?.customerLeadId,
    customerId: obj?.customerUniqueNumber,
    rId: obj?.rId,
    caseId: selectedPlan?.caseId,
    netPremium: selectedPlan?.NetPremium,
    gst: selectedPlan?.GST,
    finalPremium: selectedPlan?.FinalPremium,
    insurerId: selectedPlan?.insurerId,
    source: obj?.source,
    startingSource: "",
    employeeId: obj?.emplId,
    vehicleType: obj?.vehicleType,
    vehicleSubType: "",
    newPolicyType: obj?.newPolicyType,
    quotationNumber: "",
    salutation: obj?.gender === "M" ? "Mr." : obj?.gender === "F" ? "Mrs." : "",
    maritalStatus: obj?.maritalStatus,
    email: obj?.email,
    firstName: obj?.firstName,
    lastName: obj?.lastName,
    middleName: obj?.middleName,
    gender: obj?.gender,
    dob: obj?.dob,
    mobileNumber: obj?.mobileNumber,
    addressLine1: obj?.addressLine1,
    addressLine2: obj?.addressLine2,
    addressLine3: obj?.addressLine3,
    isRcAddressSame: true,
    rcAddressLine1: obj?.addressLine1,
    rcAddressLine2: obj?.addressLine2,
    rcAddressLine3: obj?.addressLine3,
    roadSideAssistance: selectedPlan?.RoadSideAssistance,
    engineProtection: selectedPlan?.EngineProtection,
    tyreProtection: selectedPlan?.TyreProtection,
    consumables: selectedPlan?.Consumables,
    isElectricalAccessories: selectedPlan?.IsElectricalAccessories,
    isNonElectricalAccessories: selectedPlan?.IsNonElectricalAccessories,
    isCngAccessories:
      obj?.newPolicyType === POLICY_TYPE.COMPREHENSIVE ||
      obj?.newPolicyType === POLICY_TYPE.ODONLY
        ? selectedPlan?.BiFuelOd
        : obj?.newPolicyType === POLICY_TYPE.THIRDPARTY
        ? selectedPlan?.BiFuelTp
        : false,
    cngValue: obj?.cngValue,
    cngKitType: obj?.cngKitType,
    isTppdDiscount: selectedPlan?.tppdDiscount,
    personalAccident: selectedPlan?.PersonalAccident,
    invoiceCover: selectedPlan?.InvoiceCover,
    ncbProtection: selectedPlan?.NCBProtection,
    rimProtection: selectedPlan?.RimProtection,
    isVoluntaryDeductable: selectedPlan?.VoluntaryDeductive,
    voluntaryDeductableAmount: selectedPlan?.VoluntaryDeductive
      ? obj?.voluntaryDeductableAmount
      : 0,
    lossOfPersonalBelongings: selectedPlan?.LossOfPersonalBelongings,
    zeroDepriciationCover: selectedPlan?.ZeroDepriciationCover,
    keyReplacement: selectedPlan?.KeyReplacement,
    electricAmount: selectedPlan?.IsElectricalAccessories
      ? obj?.electricAmount
      : 0,
    nonElectricAmount: selectedPlan?.IsNonElectricalAccessories
      ? obj?.nonElectricAmount
      : 0,
    passengerCoverAmount: selectedPlan?.PassengerCover
      ? obj?.passengerCoverAmount
      : 0,
    isPassengerCover: selectedPlan?.PassengerCover,
    liabilitiesToPaidDriver: selectedPlan?.liabilitiesToPaidDriver,
    noOfPaidDrivers: obj?.noOfPaidDrivers,
    isHypothecation: obj?.isHypothecation,
    hpnCity: obj?.hpnCity,
    hpnBank: obj?.hpnBank,
    hpnBankCode: obj?.hpnBankCode,
    customerType: obj?.customerType,
    planId: obj?.planId,
    isOwnerChanged: obj?.isOwnerChanged,
    businessType: obj?.businessType,
    prevInsurerCode: obj?.insurerCode,
    isPreviousInsuranceClaimed: obj?.isPreviousInsuranceClaimed,
    pincode: obj?.pincode,
    cityId: obj?.cityId,
    stateId: obj?.stateId,
    prevPolicyEndDate: obj?.prevPolicyEndDate,
    prevPolicyNumber: obj?.prevPolicyNumber,
    prevNoClaimBonus: obj?.prevNoClaimBonus,
    prevPolicyType: obj?.prevPolicyType,
    prevTpInsurerCode: obj?.prevTPInsurerCode,
    prevTpPolicyNumber: obj?.prevTPPolicyNumber,
    prevTpPolicyEndDate: obj?.prevTPPolicyEndDate,
    rtoCode: obj?.rtoCode,
    manufaturingDate: obj?.manufaturingDate,
    registrationDate: obj?.registrationDate,
    registrationNumber: obj?.registrationNumber,
    variantCode: obj?.variantId,
    idv: obj?.idv,
    engineNumber: obj?.engineNumber,
    chassisNumber: obj?.chassisNumber,
    isValidPuc: obj?.isValidPuc,
    pucNumber: obj?.pucNumber,
    pucValidUpto: obj?.pucValidUpto,
    nomineeName: obj?.nomineeName,
    nomineeDob: obj?.nomineeDob,
    nomineeRelationCode: obj?.nomineeRelation,
    // nomineeRelationCode: obj?.nomineeRelationCode,
    appointeeName: "",
    appointeeDob: "",
    appointeeRelationCode: "",
    reasonForNotOptingPersonalAccident: "already have one",
    kycStatus: "",
    kycData: newKYCData,
    planId: selectedPlan?.planId,
    reasonForNotOptingCpa: obj?.reasonForNotOptingCpa,
    prevPAPolicyNumber: obj?.prevPAPolicyNumber,
    prevPASumInsured: obj?.prevPASumInsured,
    prevPAInsurerCode: obj?.prevPAInsurerCode,
    prevPAPolicyEndDate: obj?.prevPAPolicyEndDate,
    isPrevZeroDepreciationCover: obj?.isPrevZeroDepreciationCover,
    isPrevInvoiceCover: obj?.isPrevInvoiceCover,
    isPrevEngineProtection: obj?.isPrevEngineProtection,
    isPrevTyreProtection: obj?.isPrevTyreProtection,
    panNumber: obj?.panNumber,
    adhaarNumber: obj?.adhaarNumber,
    hpnBankData: obj?.hpnBankData,
  };
};

export const createQuotePayloadObj = (reqObj) => {
  return {
    BrokerId: "SARL",
    quotationNumber: reqObj.quotationNumber,
    CompanyCode: "DIGIT",
    UniqueId: reqObj.ApiId,
    VehicleType: reqObj.VehicleType,
    NewPolicyType: reqObj.NewPolicyType,
    PolicyEndDate: reqObj.PolicyEndDate,
    PolicyStartDate: reqObj.PolicyStartDate,
    PaymentAmount: reqObj.PaymentAmount,
    PaymentId: reqObj.PaymentId,
    ProposalId: reqObj.ProposalId,
    RelianceRTOCode: reqObj.RelianceRTOCode,
    ApiUniqueNumber: {
      Bajaj: reqObj.ApiUniqueNumber.Bajaj,
      Digit: reqObj.ApiUniqueNumber.Digit,
      HDFC: reqObj.ApiUniqueNumber.HDFC,
      Shriram: reqObj.ApiUniqueNumber.Shriram,
      Kotak: reqObj.ApiUniqueNumber.Kotak,
      Reliance: reqObj.ApiUniqueNumber.Reliance,
      Future: reqObj.ApiUniqueNumber.Future,
      Royal: reqObj.ApiUniqueNumber.Royal,
      Magma: reqObj.ApiUniqueNumber.Magma,
      Sompo: reqObj.ApiUniqueNumber.Sompo,
    },
    KYC: {
      HDFC: reqObj.KYC.HDFC,
      Future: reqObj.KYC.Future,
      Reliance: reqObj.KYC.Reliance,
      RoyalRefNo: reqObj.KYC.RoyalRefNo,
      Royal: reqObj.KYC.Royal,
      Shriram: reqObj.KYC.Shriram,
      AadharNo: reqObj.KYC.AadharNo,
      FatherName: reqObj.KYC.FatherName,
      MotherName: reqObj.KYC.MotherName,
      AadharBackPic: reqObj.KYC.AadharBackPic,
      PassportPic: reqObj.KYC.PassportPic,
      Zuno: reqObj.KYC.Zuno,
      ZunoRef: reqObj.KYC.ZunoRef,
      LibertyRefNo: reqObj.KYC.LibertyRefNo,
      Liberty: reqObj.KYC.Liberty,
      Sompo: reqObj.KYC.Sompo,
    },
    Contactdetails: {
      Salutation: reqObj.Salutation,
      Email: reqObj.Email,
      FirstName: reqObj.FirstName,
      LastName: reqObj.LastName,
      MiddleName: reqObj.MiddleName,
      MobileNumber: reqObj.MobileNumber,
      OTPNumber: 20359,
      SubProductID: 2,
      FlatNumber: reqObj.FlatNumber,
      StreetNumber: reqObj.StreetNumber,
      Street: reqObj.Street,
      District: reqObj.District,
      StateCode: reqObj.StateCode,
      State: reqObj.State,
      City: reqObj.City,
      Country: reqObj.Country,
      Pincode: reqObj.Pincode,
      PermanentAddress: reqObj.PermanentAddress,
      Dob: reqObj.Dob,
      Gender: reqObj.Gender && reqObj.Gender.toUpperCase(),
      PanNumber: reqObj.PanNumber,
      PanImage: reqObj.PanImage,
      GSTNo: reqObj.GSTNo,
    },
    AddOns: {
      RoadSideAssistance: reqObj.AddOns.RoadSideAssistance,
      EngineProtection: reqObj.AddOns.EngineProtection,
      TyreProtection: reqObj.AddOns.TyreProtection,
      RimProtection: reqObj.AddOns.RimProtection,
      Consumables: reqObj.AddOns.Consumables,
      IsElectricalAccessories: reqObj.AddOns.IsElectricalAccessories,
      IsNonElectricalAccessories: reqObj.AddOns.IsNonElectricalAccessories,
      IsCngAccessories: reqObj.AddOns.IsCngAccessories,
      PersonalAccident: reqObj.AddOns.PersonalAccident,
      InvoiceCover: reqObj.AddOns.InvoiceCover,
      EngineGearBox: reqObj.AddOns.EngineGearBox,
      NCBProtection: reqObj.AddOns.NCBProtection,
      VoluntaryDeductive: reqObj.AddOns.VoluntaryDeductive,
      PassengerCover: reqObj.AddOns.PassengerCover,
      LossOfPersonalBelongings: reqObj.AddOns.LossOfPersonalBelongings,
      ZeroDepriciationCover: reqObj.AddOns.ZeroDepriciationCover,
      KeyReplacement: reqObj.AddOns.KeyReplacement,
      ElectricAmount:
        reqObj.AddOns.IsElectricalAccessories == true
          ? reqObj.AddOns.ElectricAmount
          : 0,
      NonElectricAmount:
        reqObj.AddOns.IsNonElectricalAccessories == true
          ? reqObj.AddOns.NonElectricAmount
          : 0,
      VoluntaryDeductiveAmount:
        reqObj.AddOns.VoluntaryDeductive == true
          ? reqObj.AddOns.VoluntaryDeductiveAmount
          : 0,
      PassengerCoverAmount:
        reqObj.AddOns.PassengerCover === true
          ? reqObj.AddOns.PassengerCoverAmount
          : 0,
      CNGValue: reqObj.AddOns.CNGValue,
      discount: reqObj.AddOns.discount,
      LiabilitiesToPaidDriver: reqObj.AddOns.LiabilitiesToPaidDriver,
      NoOfLegelDrives: reqObj.AddOns.NoOfLegelDrives,
    },
    Hypothecation: {
      IsHypothecation: reqObj.IsHypothecation,
      HpnCity: reqObj.HpnCity,
      HpnBank: reqObj.HpnBank,
      HDFC: reqObj.HpnHDFCID,
    },
    CustomerType: reqObj.CustomerType,
    DeviceId: "Web",

    IsPreviousInsurerKnown: reqObj.IsPreviousInsurerKnown,
    IsBreakingCase:
      reqObj.PolicyStatus == "expired within 90 day" ||
      reqObj.PolicyStatus == "expired above 90 day"
        ? true
        : false,
    IsExistingPACover: false,
    IsODOnly: false,
    IsOwnerChanged: false,
    IsPOSIncluded: false,
    IsRecalculateQuote: false,
    IsThirdPartyOnly: false,
    IsValidLicence: true,
    LoginUserId: 0,
    MakeName: reqObj.MakeName,
    ModelName: reqObj.ModelName,
    NewBusinessPolicyType: "0",
    OrganizationName: null,

    PrevPolicyExpiryStatus: reqObj.PolicyStatus,

    PlanId: 74,
    PolicyEndDate: reqObj.PolicyEndDate,
    PolicyStartDate: reqObj.PolicyStartDate,
    PolicyType: "Renewal",
    PreviousPolicyDetails: {
      InsurerCode: reqObj.PreInsurerCode,
      InsurerCompany: reqObj.PreInsurerComp,
      IsPreviousInsuranceClaimed: reqObj.PreviousInsuranceClaimed,
      IsPreviousOwnerChanged: reqObj.PreviousOwnerChanged,
      PolicyEndDate: reqObj.PrePolicyEndDate,
      PolicyNumber: reqObj.PrePolicyNumber,
      PreviousNoClaimBonus: reqObj.PreviousNoClaimBonus,
      PreviousPolicyType: reqObj.PreviousPolicyType,
    },
    PreviousTpPolicyDetails: {
      PreTPInsurerCode: reqObj.PreTPInsurerCode,
      PreTPPolicyNumber: reqObj.PreTPPolicyNumber,
      PreTPPolicyEndDate: reqObj.PreTPPloicyEndDate,
    },
    PreviousPolicyDetailsRequired: true,
    ProductCode: reqObj.ProductCode,
    QuotationNumber: "SARLMTRPC2022071516433185",
    RTOCityName: reqObj.RtoCode,
    RequestedAddOnList: [],
    SubProductCode: 2,
    Uid: "3292d524-b60d-4c14-aff7-1e07d096af4a",
    VariantName: reqObj.VariantCode,
    VehicleDetails: {
      IsVehicleNew: reqObj.IsVehicleNew,
      IsInternalCNG: reqObj.IsInternalCNG,
      BPRtoId: "976",
      BimaPostRTOId: "976",
      ManufaturingDate: reqObj.ManufaturingDate,
      PurchaseDate: reqObj.ManufaturingDate,
      RegistrationDate: reqObj.RegistrationDate,
      RegistrationNumber: reqObj.RegistrationNumber,
      VariantCode: {
        Bajaj: reqObj.VariantCode.Bajaj,
        Digit: reqObj.VariantCode.Digit,
        HDFC: reqObj.VariantCode.HDFC,
        Shriram: reqObj.VariantCode.Shriram,
        Kotak: reqObj.VariantCode.Kotak,
        Reliance: parseInt(reqObj.VariantCode.Reliance),
        Future: reqObj.VariantCode.Future,
        Royal: reqObj.VariantCode.Royal,
        Liberty: reqObj.VariantCode.Liberty,
        Sompo: reqObj.VariantCode.Sompo,
      },
      RegistrationYear: reqObj.RegistrationYear,
      Idv: reqObj.Idv,
      EngineNumber: reqObj.EngineNumber,
      ChassisNumber: reqObj.ChassisNumber,
      VehicleMakeCode: reqObj.VehicleMakeCode,
      VehicleModelCode: reqObj.VehicleModelCode,
      VehicleSubTypeCode: reqObj.VehicleSubTypeCode,
      CarryingCapacity: reqObj.CarryingCapacity,
      CubicCapacity: reqObj.CubicCapacity,
      Color: reqObj.Color,
      NCB: "0",
      RegistrationCity: reqObj.RegistrationCity,
      MakeName: reqObj.MakeName,
      ModelName: reqObj.ModelName,
      VariantName: reqObj.VariantName,
      FuelType: reqObj.FuelType,
      IsValidPuc: reqObj.IsValidPuc,
      PUCNumber: reqObj.PUCNumber,
      PUCValidUpto: reqObj.PUCValidUpto,
    },
    Nominee: {
      FirstName: reqObj.NomineeFirstName,
      LastName: reqObj.NomineeLastName,
      Relationship: reqObj.NomineeRelationship,
      DateOfBirth: reqObj.NomineeDateOfBirth,
      Gender: reqObj.NomineeGender,
      MobineNumber: reqObj.NomineeMobileNumber,
    },
  };
};

export const createOnlinePolicyObj = (body) => {
  let obj = {
    ...(body?.RegistrationNumber && { vehicle_no: body?.RegistrationNumber }),
    ...(body?.ManufaturingDate && { vehicle_mfg_yr: body?.ManufaturingDate }),
    ...(body?.RegistrationDate && {
      registration_date: body?.RegistrationDate,
    }),
    ...(body?.FuelType && { fuel_type: body?.FuelType }),
    ...(body?.VehicleType && {
      vehicle_type:
        body?.VehicleType === "2w"
          ? "MotorBike"
          : body?.VehicleType === "4w"
          ? "Pvt Car"
          : body?.VehicleType === "gcv"
          ? "Goods Carrying"
          : body?.VehicleType === "pcv" && "Passenger Carrying",
    }),
    ...(body?.MakeName && { vehicle_make: body?.MakeName }),
    ...(body?.ModelName && { vehicle_model: body?.ModelName }),
    ...(body?.VariantName && { vehicle_variant: body?.VariantName }),
    ...(body?.rm_name_Code && { rm_name_Code: body?.rm_name_Code }),
    ...(body?.NewPolicyType && {
      policy_type:
        body?.NewPolicyType === "Comprehensive"
          ? "comprehensive"
          : body?.NewPolicyType == "ThirdParty"
          ? "third_party"
          : "own_damage",
    }),
    ...(body?.gaskit_installed && { gaskit_installed: body?.gaskit_installed }),
    ...(body?.PolicyStatus && { policy_status: body.PolicyStatus }),
    ...(body.insurer && { insurance_company: body.insurer }),
    ...(body?.AddOns && { addons: JSON.stringify(body?.AddOns) }),
    ...(body.ApiId && { policy_no: body.ApiId }),
    ...(body?.idv && { require_idv: body?.idv }),
    ...(body?.idv && { idv: body?.idv }),
    ...(body?.require_discount && { require_discount: body?.require_discount }),
    ...(body?.expected_final_premium && {
      expected_final_premium: body?.expected_final_premium,
    }),
    ...(body?.policyIdDb && { policyId: body?.policyIdDb }),
    ...(body?.PolicyStartDate && { policy_starts: body?.PolicyStartDate }),
    ...(body?.PolicyEndDate && { policy_expires: body?.PolicyEndDate }),
    ...(body?.PolicyStartDate && { policy_issue: body?.PolicyStartDate }),
    ...(body?.PolicyStartDate && { policy_recieve: body?.PolicyStartDate }),
    ...(body?.BasicODPremium && { od_net_premium: body?.BasicODPremium }),
    ...(body?.BasicTPPremium && { terrorism_prem: body?.BasicTPPremium }),
    ...(body?.GST && { tax_amount: body?.GST }),
    ...(body?.NetPremium && { net_premium: body?.NetPremium }),
    ...(body?.EngineNumber && { engine_no: body?.EngineNumber }),
    ...(body?.ChassisNumber && { chassis_no: body?.ChassisNumber }),
    ...(body?.FinalPremium && { gross_premium: body?.FinalPremium }),
    ...(body?.file && { file: body?.file }),
    ...(body?.file_type && { file_type: body?.file_type }),
    ...(body?.PreInsurerComp && {
      previous_policy_insurance_company: body?.PreInsurerComp,
    }),
    ...(body?.PreviousNoClaimBonus && {
      previous_policy_discount: body?.PreviousNoClaimBonus,
    }),
    ...(body?.PolicyStatus && {
      isBreakIn: body.PolicyStatus == "continue" ? false : true,
    }),
    ...(body?.inspection_no && { inspection_no: body.inspection_no }),
    ...(body?.RTOCityName && { rto: body.RTOCityName }),
    ...(body?.PrePolicyEndDate && {
      previous_policy_end_date: body.PrePolicyEndDate,
    }),
    ...(body?.PreInsurerCode && {
      previous_policy_insurance_company: body.PreInsurerCode,
    }),
    ...(body?.PolicyStartDate && { policy_start: body.PolicyStartDate }),
    ...(body?.PolicyStartDate && { policy_issue: body.PolicyStartDate }),
    ...(body?.PolicyEndDate && { policy_expiry: body.PolicyEndDate }),
    ...(body?.VariantCode.vehicleId && {
      vehicleId: body.VariantCode.vehicleId,
    }),
    ...(body?.PrePolicyNumber && { previous_policy_no: body?.PrePolicyNumber }),
    case_type: body.IsVehicleNew === true ? "new" : "rollover",
  };
  const newObj = new Object();
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      newObj[key] = obj[key];
    }
  }
  return newObj;
};

export function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
