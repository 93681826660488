import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  getKycConfig,
  getKycStatus,
  getNomineeRelations,
  processFlow,
} from "../services/TPApiCall";
import {
  dispatchKycConfig,
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
} from "../../store/action/userActions";
import { POLICY_TYPE, kycType } from "../utility/constants";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { BsDownload } from "react-icons/bs";
import { PDFDocument } from "pdf-lib";

const PremiumBreakup = ({ show, setModal, planDetails }) => {
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const toggle = () => setModal(!show);
  let planDetail = planDetails ? planDetails : motorRequest?.planDetail;
  console.log("PLAN DETAIL", planDetail, motorRequest?.planDetail);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: "320px" });
  const apiRequestQQ = useSelector((state) => state?.root?.apiRequestQQ);
  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );
  const [kycStatus, setKycStatus] = useState(false);
  const [addonCoverStatus, setAddOnStatusCover] = useState(false);
  const [kycConfigData, setKycConfigData] = useState(false);
  const [configData, setConfigData] = useState(null);

  const kycConfig = async () => {
    try {
      const response = await getKycConfig(
        planDetail?.insurerId,
        motorRequest?.customerType
      );

      if (!response) {
        throw new Error("No response from getKycConfig");
      }

      console.log("response kyc config", response);

      dispatchKycConfig(response?.data);
      setConfigData(response?.data);
      setKycConfigData(true);
      dispatchProcessFlowMotor("step8.kycConfig", response?.data);
      console.log("step8.kycConfig", response?.data);

      if (response?.data?.kycConfig?.isCkycAllowed) {
        dispatchMotorQuote("kycType", kycType?.ckyc);
      } else if (response?.data?.kycConfig?.isOvdAllowed) {
        dispatchMotorQuote("kycType", kycType?.ovd);
      } else {
        dispatchMotorQuote("kycType", kycType?.ckyc);
      }
    } catch (error) {
      console.error(
        "Error fetching KYC config for insurer:",
        insurer?.Api_name,
        error
      );
    }
  };

  const getKycycStatus = async () => {
    const response = await getKycStatus(motorRequest, planDetail);
    console.log("response kyc status", response);
    // response?.data?.kycStatus === "success"
    //   ? setKycStatus(true)
    //   : response?.data?.kycStatus === "pending"
    //   ? setKycStatus(true)
    //   : setKycStatus(false);
    if (response?.data?.kycStatus === "") {
      setKycStatus(false);
      dispatchMotorQuote("kycStatus", "");
      dispatchMotorQuote("kycData", "");
      dispatchProcessFlowMotor("step8.kycStatus", "");
      let dataForKyc = { ...processFlowObjMotor?.step8?.kycAndUserDetails };
      if (dataForKyc?.kycStatus === "completed") {
        dataForKyc.kycStatus = "";
      }
      console.log("dataForKyc", dataForKyc);
      console.log("dataForKyc updated", dataForKyc);
      console.log("motorRequest", motorRequest);
      let newMotorRequest = { ...motorRequest };
      newMotorRequest.kycStatus = "";
      console.log("newMotorRequest", newMotorRequest);
      const newData = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        step: "step7",
        motorRequest: { ...newMotorRequest },
        processDiscription: "quotation",
        step7: {
          ...processFlowObjMotor.step7,
          motorRequest: { ...newMotorRequest },
          QuickQouteResult: { ...QuickQouteResult },
        },
      };
      processFlow(newData);
      console.log("newData premiumBreakup", newData);
    } else {
      setKycStatus(true);
      dispatchProcessFlowMotor("step8.kycStatus", "completed");
    }
  };

  useEffect(() => {
    kycConfig();
    getKycycStatus();
    const addonCoversStatus = addonCovers();
    setAddOnStatusCover(addonCoversStatus);
  }, []);

  const addonCovers = () => {
    if (
      !planDetail?.RoadSideAssistance ||
      planDetail?.TyreProtection ||
      planDetail?.RimProtection ||
      planDetail?.Consumables ||
      planDetail?.EngineGearBox ||
      planDetail?.EngineProtection ||
      planDetail?.IsElectricalAccessories ||
      planDetail?.IsNonElectricalAccessories ||
      planDetail?.InvoiceCover ||
      planDetail?.VoluntaryDeductive ||
      planDetail?.NCBProtection ||
      planDetail?.PassengerCover ||
      planDetail?.LossOfPersonalBelongings ||
      planDetail?.ZeroDepriciationCover ||
      planDetail?.KeyReplacement ||
      planDetail?.PaCoverAmount ||
      planDetail?.PassengerCoverAmount ||
      planDetail?.ElectricAmount ||
      planDetail?.nonElectricalAmount ||
      planDetail?.VoluntaryAmount ||
      planDetail?.CNGValue ||
      planDetail?.DriverLiability
    ) {
      return true;
    } else {
      return false;
    }
  };

  const generatePdf = async () => {
    const input = document.getElementById("premiumBreakup");
    const inputWidth = input.offsetWidth;
    const inputHeight = input.offsetHeight;
    const scale = 2;
    const canvas = await html2canvas(input, { scale: scale, useCORS: true });
    const imgData = canvas.toDataURL("image/png");

    // let pdf = new jsPDF("p", "pt", [
    //   (inputWidth * scale) / 3.78,
    //   (inputHeight * scale) / 3.78,
    // ]);
    const pdf = await PDFDocument.create();
    const page = pdf.addPage([
      (inputWidth * scale) / 3.78,
      (inputHeight * scale) / 3.78,
    ]);
    const pngImage = await pdf.embedPng(imgData);
    // pdf.addImage(
    //   imgData,
    //   "PNG",
    //   0,
    //   0,
    //   (inputWidth * scale) / 3.78,
    //   (inputHeight * scale) / 3.78
    // );
    // // pdf = addWaterMark(pdf);
    // pdf.setFontSize(40);
    // pdf.setTextColor(86, 185, 108);
    // // pdf.setDrawColor(255, 255, 255);
    // pdf.text(
    //   "insurefast",
    //   pdf.internal.pageSize.getWidth() / 2,
    //   pdf.internal.pageSize.getHeight() / 2,
    //   { align: "center", angle: 45 }
    // );
    page.drawImage(pngImage, {
      x: 0,
      y: 0,
      width: (inputWidth * scale) / 3.78,
      height: (inputHeight * scale) / 3.78,
    });

    const pdfBytes = await pdf.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "premiumBreakupDownload.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={toggle}
        centered
        size="lg"
        id="premiumBreakup"
      >
        <ModalHeader className="d-flex">
          <div className="modal_heading d-flex align-items-center">
            <h4 className="text-center mb-0">Plan Details</h4>

            <div onClick={toggle} class="v2_close false">
              <img
                src="/assets/images/delete_icon.svg"
                title="Close"
                alt="close"
              />
            </div>
          </div>
          <div className="premiumBreakupLogo">
            <img
              src="/assets/images/insurefastlogos.svg"
              title="Close"
              alt="close"
            />
          </div>
          <div className="premiumBreakupCloseImg">
            <BsDownload onClick={generatePdf} />
          </div>
        </ModalHeader>

        <ModalBody>
          {/* <div className="modal_heading w-100 mb-5">
            <h4 className="text-center fs-3">Premium Breakup</h4>
            <div onClick={toggle} class="v2_close false">
              <img
                src="./assets/images/delete_icon.svg"
                title="Close"
                alt="close"
              />
            </div>
          </div> */}
          <div>
            <div className="d-lg-flex d-md-flex d-flex align-items-start justify-content-between universal_sompo">
              <img
                src={planDetail?.Logo}
                className="nvimg"
                alt=""
                style={{ width: "120px" }}
              />
              <h1 className="my-lg-0">
                {planDetail?.insurer}
                <p
                  className="fs-8 text-muted mt-lg-1 mt-md-2 mb-lg-0 mt-sm-1 mt-1"
                  style={{ color: "#56b96c" }}
                >
                  {`(${
                    planDetail?.policyType === POLICY_TYPE?.COMPREHENSIVE
                      ? "Comprehensive"
                      : planDetail?.policyType === POLICY_TYPE?.THIRDPARTY
                      ? "Third Party"
                      : planDetail?.policyType === POLICY_TYPE?.ODONLY
                      ? "Own Damage"
                      : planDetail?.policyType
                  })`}
                </p>
              </h1>

              <div className="d-flex flex-column">
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    kycConfigData &&
                      navigate(`/proposal/${motorRequest?.customerLeadId}`, {
                        state: { kycStatus: kycStatus },
                      });
                    dispatchMotorQuote("idv", planDetail?.idv);
                    dispatchMotorQuote("caseId", planDetail?.caseId);
                  }}
                  className="btn-primary"
                  // style={{
                  //   fontWeight: 500,
                  //   border: "none",
                  //   background: "#56b96c",
                  //   color: "white",
                  // }}
                >
                  {kycConfigData ? (
                    "BUY NOW"
                  ) : (
                    <div class="spinner-border text-light" role="status">
                      {/* <span class="visually-hidden">Loading...</span> */}
                    </div>
                  )}
                  {/* {planDetail?.FinalPremium} */}
                </a>
              </div>
            </div>
            <div
              className="row mx-0 justify-content-between"
              // style={{ background: "#56b96c" }}
            >
              <div
                className={`col-lg-4 ${
                  isMobile ? "col-12 mb-3" : "col-6"
                }  ps-lg-0 p-0`}
              >
                <small className="cover_value">Cover Value (IDV)</small>
                <p className="amount">
                  {motorRequest?.newPolicyType !== POLICY_TYPE.THIRDPARTY ? (
                    <>
                      {planDetail?.idv ? (
                        Math.round(planDetail.idv)
                      ) : (
                        <div class="spinner-border" role="status">
                          {/* <span class="sr-only">Loading...</span> */}
                        </div>
                      )}
                    </>
                  ) : (
                    <small className="col-5 fs-6">
                      <strong>Cover: </strong>Third Party
                    </small>
                  )}
                </p>
              </div>

              <div
                className={`col-lg-4 ${
                  isMobile ? "col-12" : "col-6"
                }  ps-lg-0 p-0 d-none`}
              >
                <small className="cover_value">Tenure</small>
                <p className="amount">1 year OD + 3 year TP</p>
              </div>

              <div
                className={`col-lg-4 ${
                  isMobile ? "col-12 text-start" : "col-6 text-end"
                }  ps-lg-0 p-0`}
              >
                <small className="cover_value">Policy Start Date</small>
                <p className="amount">
                  {moment(planDetail?.StartDate, "YYYY-MM-DD").format(
                    "DD MMM, YYYY"
                  )}
                </p>
              </div>
            </div>

            <div className="row mx-0 p-1  mt-2">
              <div className="col-12 px-0">
                <h1 className="premium_heading">Premium Breakup</h1>
              </div>
              <div className="col-lg-12 px-0">
                <small className="basic-cover" style={{ color: "#56b96c" }}>
                  Basic Covers
                </small>
              </div>

              <div className="col-lg-12 px-0">
                <table className="table">
                  <tbody>
                    <tr>
                      <th className="cover_value">Basic Own Damage</th>

                      <td style={{ textAlign: "end" }} className="amount">
                        {parseInt(planDetail?.BasicODPremium)}
                      </td>
                    </tr>
                    <tr>
                      <th className="cover_value">Basic Third Party Premium</th>

                      <td style={{ textAlign: "end" }} className="amount">
                        {parseInt(planDetail?.BasicTPPremium)}
                      </td>
                    </tr>
                    {planDetail?.IsElectricalAccessories && (
                      <tr>
                        <th className="cover_value">
                          Electrical Accessories Premium{" "}
                          {planDetail?.ElectricAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.ElectricAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail?.IsNonElectricalAccessories && (
                      <tr>
                        <th className="cover_value">
                          Non Electrical Accessories Premium{" "}
                          {planDetail?.nonElectricalAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.nonElectricalAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail?.BiFuelOd && (
                      <tr>
                        <th className="cover_value">
                          BiFuelOd Premium
                          {planDetail?.BiFuelOdAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.BiFuelOdAmount)}
                        </td>
                      </tr>
                    )}
                    {planDetail?.BiFuelTp && (
                      <tr>
                        <th className="cover_value">
                          BiFuel Tp Premium
                          {planDetail?.BiFuelTpAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.BiFuelTpAmount)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {addonCoverStatus && (
                <div className="col-lg-12 px-0">
                  <small className="basic-cover" style={{ color: "#56b96c" }}>
                    Add-on Covers
                  </small>
                </div>
              )}

              <div className="col-lg-12 px-0">
                <table className="table fs-6" style={{ fontSize: "12px" }}>
                  <tbody>
                    {planDetail?.RoadSideAssistance && (
                      <tr>
                        <th className="cover_value">
                          Road Side Assistance
                          {planDetail?.RoadSideAssistanceAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.RoadSideAssistanceAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.EngineProtection && (
                      <tr>
                        <th className="cover_value">
                          Engine Protection{" "}
                          {planDetail?.EngineProtectionAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.EngineProtectionAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.TyreProtection && (
                      <tr>
                        <th className="cover_value">
                          Tyre Protection{" "}
                          {planDetail?.TyreProtectionAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.TyreProtectionAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.RimProtection && (
                      <tr>
                        <th className="cover_value">
                          Rim Protection{" "}
                          {planDetail?.RimProtectionAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.RimProtectionAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.Consumables && (
                      <tr>
                        <th className="cover_value">
                          Consumables{" "}
                          {planDetail?.ConsumablesAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.ConsumablesAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.PersonalAccident && (
                      <tr>
                        <th className="cover_value">
                          Personal Accident{" "}
                          {planDetail?.PaCoverAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.PaCoverAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.InvoiceCover && (
                      <tr>
                        <th className="cover_value">
                          InvoiceCover{" "}
                          {planDetail?.InvoiceCoverAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.InvoiceCoverAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.PassengerCover && (
                      <tr>
                        <th className="cover_value">
                          Passenger Cover{" "}
                          {planDetail?.PassengerCoverAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.PassengerCoverAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.LossOfPersonalBelongings && (
                      <tr>
                        <th className="cover_value">
                          Loss Of Personal Belongings{" "}
                          {planDetail?.LossOfPersonalBelongingsAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.LossOfPersonalBelongingsAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.ZeroDepriciationCover && (
                      <tr>
                        <th className="cover_value">
                          Zero Depriciation Cover{" "}
                          {planDetail?.ZeroDepriciationCoverAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.ZeroDepriciationCoverAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.KeyReplacement && (
                      <tr>
                        <th className="cover_value">
                          Key Replacement{" "}
                          {planDetail?.KeyReplacementAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.KeyReplacementAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.NCBProtection && (
                      <tr>
                        <th className="cover_value">
                          NCB Protection
                          {planDetail?.NCBProtectionAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.NCBProtectionAmount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.liabilitiesToPaidDriver && (
                      <tr>
                        <th className="cover_value">
                          Liabilities To Paid Driver{" "}
                          {planDetail?.liabilitiesToPaidDriverAmount === 0
                            ? " (Included)"
                            : null}
                        </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.liabilitiesToPaidDriverAmount)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="col-lg-12 px-0">
                <small className="basic-cover" style={{ color: "#56b96c" }}>
                  Discounts
                </small>
              </div>
              <div className="col-lg-12 px-0">
                <table className="table  fs-6" style={{ fontSize: "12px" }}>
                  <tbody>
                    {/* {planDetail?.VoluntaryDeductive && (
                      <tr>
                        <th className="cover_value">Voluntary Deductive</th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {planDetail?.VoluntaryAmount}
                        </td>
                      </tr>
                    )} */}

                    {planDetail?.NCBDiscount != false && (
                      <tr>
                        <th className="cover_value">NCB Discount</th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {parseInt(planDetail?.NCBDiscount)}
                        </td>
                      </tr>
                    )}

                    {planDetail?.tppdDiscount != false && (
                      <tr>
                        <th className="cover_value">Tppd Discount</th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {planDetail?.tppdDiscount}
                        </td>
                      </tr>
                    )}

                    {planDetail?.discount != false && (
                      <tr>
                        <th className="cover_value">OD Discount</th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {planDetail?.discount}
                        </td>
                      </tr>
                    )}

                    {planDetail?.loading && (
                      <tr>
                        <th className="cover_value"> Loading Premium</th>
                        <td style={{ textAlign: "end" }} className="amount">
                          {planDetail?.loadingPremium}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="col-lg-12 px-0">
                <small className="basic-cover" style={{ color: "#56b96c" }}>
                  Premium
                </small>
              </div>
              <div className="col-lg-12 px-0">
                <table className="table  fs-6" style={{ fontSize: "12px" }}>
                  <tbody>
                    {planDetail?.NetPremium != false && (
                      <tr>
                        <th className="cover_value">Net Premium</th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {" "}
                          {
                            planDetail?.NetPremium === true ? (
                              <span>(Included)</span>
                            ) : typeof planDetail?.NetPremium === "number" ? (
                              Math.round(
                                planDetail?.NetPremium.toString().replace(
                                  "INR",
                                  ""
                                )
                              )
                            ) : typeof planDetail?.NetPremium === "string" ? (
                              Math.round(
                                planDetail?.NetPremium.replace("INR", "")
                              )
                            ) : (
                              planDetail?.NetPremium || "0"
                            )
                            // Math.round(planDetail?.discount)
                          }
                        </td>
                      </tr>
                    )}

                    {planDetail?.GST != false && (
                      <tr>
                        <th className="cover_value">GST (18%) </th>

                        <td style={{ textAlign: "end" }} className="amount">
                          {" "}
                          {
                            planDetail?.GST === true ? (
                              <span>(Included)</span>
                            ) : typeof planDetail?.GST === "number" ? (
                              Math.round(
                                planDetail?.GST.toString().replace("INR", "")
                              )
                            ) : typeof planDetail?.GST === "string" ? (
                              Math.round(planDetail?.GST.replace("INR", ""))
                            ) : (
                              planDetail?.GST
                            )
                            // Math.round(planDetail?.GST)
                          }
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="col-12 px-0">
                <div
                  className="card"
                  style={{
                    backgroundColor: "#56b96c",
                    border: "1px solid #56b96c",
                  }}
                >
                  <div
                    className="card-footer d-flex justify-content-between text-white"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(`/proposal/${motorRequest?.customerLeadId}`, {
                        state: { kycStatus: kycStatus },
                      });
                      dispatchMotorQuote("idv", planDetail?.idv);
                      dispatchMotorQuote("caseId", planDetail?.caseId);
                    }}
                  >
                    <h2 className="mb-0 fs-md-5 fs-6">Total Payble</h2>
                    <p className="mb-0 fs-md-5 fs-6">
                      {" "}
                      {
                        planDetail?.FinalPremium === true ? (
                          <span>(Included)</span>
                        ) : typeof planDetail?.FinalPremium === "number" ? (
                          "₹" +
                          Math.round(
                            planDetail?.FinalPremium.toString().replace(
                              "INR",
                              ""
                            )
                          )
                        ) : typeof planDetail?.FinalPremium === "string" ? (
                          "₹" +
                          Math.round(
                            planDetail?.FinalPremium.replace("INR", "")
                          )
                        ) : (
                          planDetail?.FinalPremium
                        )
                        // Math.round(planDetail?.FinalPremium)
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PremiumBreakup;
