/*
 * Api helper File
 * Use for all Api.
 **/
/*
npm run build
*/
// api helper file here
import axios from "axios";
import cookie from "react-cookies";

// const MainUrl = "http://localhost:10111/api/v1/";
const MainUrl = "https://api.insurefast.in/api/v1/";
// const APP_URL = "https://api.insurefast.in/";
// const APP_URL = "http://localhost:10111/";
// const MainUrl = "https://uatapi.insurefast.in/api/v1/";
// const APP_URL = "https://uatapi.insurefast.in/";
// const MainUrl = "https://test.insurefast.in/api/v1/";
// const APP_URL = "https://test.insurefast.in/";

// uat url
// export function APPURL() {
//   return "https://uatapi.insurefast.in/";
// }

// live url
export function APPURL() {
  return "https://api.insurefast.in/";
}

// local url
// export function APPURL() {
// return "http://localhost:10111/";
// }

//Post Data Functions.
export function PostData(url, data) {
  // body..
  var headers = {
    "Content-Type": "application/json",
    "X-localization": "en",
    "Access-Control-Allow-Headers": "Authorization, Accept",
  };
  return axios
    .post(MainUrl + url, data, { headers: headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      return errorStatus;
    });
}

//Get Data Functions.
export function GetData(url, data) {
  // body...

  var headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Headers": "Authorization, Accept",
    // 'Authorization':'Bearer '+Token,
    // 'X-localization':'en'
  };
  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(MainUrl + url, data, { headers: headers })
    .then((res) => {
      //console.log(res);
      //console.log(res.data);
      return res.data;
    })
    .catch((error) => {
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

//Post Data with token
//Post Data Functions.
export function PostDataWithToken(url, data) {
  // body..
  //
  let tokens = "";
  if (cookie.load("access_token")) {
    tokens = cookie.load("access_token");
  }
  var headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + tokens,
    "Access-Control-Allow-Headers": "Authorization, Accept",
  };
  return axios
    .post(MainUrl + url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

//Post Data Functions.
export function PostImageDataWithToken(url, data) {
  // body..
  //
  let tokens = "";
  if (cookie.load("access_token")) {
    tokens = cookie.load("access_token");
  }
  var headers = {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + tokens,
    "Access-Control-Allow-Headers": "Authorization, Accept",
  };
  return axios
    .post(MainUrl + url, data, { headers: headers })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}
//Get Data with token Functions.
export function GetDataWithToken(url, data) {
  let tokens = "";

  if (cookie.load("access_token")) {
    tokens = cookie.load("access_token");
  }
  let config = {
    headers: {
      Authorization: "Bearer " + tokens,
    },
    params: {},
  };

  //console.log("headers="+JSON.stringify(headers));
  return axios
    .get(MainUrl + url, config)
    .then((res) => {
      //console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log("errors", error);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}

export function PostVideoDataWithToken(url, data, onUploadProgress) {
  // body..
  //
  let tokens = "";
  if (cookie.load("access_token")) {
    tokens = cookie.load("access_token");
  }
  var headers = {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Headers": "Authorization, Accept",
    Authorization: "Bearer " + tokens,
  };
  return axios
    .post(MainUrl + url, data, {
      headers: headers,
      onUploadProgress: onUploadProgress,
    })
    .then((response) => {
      //console.log(res);
      //console.log(res.data);
      return response.data;
    })
    .catch((error) => {
      //return error.data;
      //console.log(error.response);
      let errorStatus = JSON.parse(JSON.stringify(error.response));
      //console.log(errorStatus.data);
      return errorStatus;
    });
}
