import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  CUSTOMER_TYPE,
  POLICY_TYPE,
  insurers,
  kycDoc,
  kycDocName,
  kycType,
  toastFailure,
  toastSuccess,
} from "../utility/constants";
import { useForm } from "react-hook-form";
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
} from "../../store/action/userActions";
import {
  getKyc,
  getKycServiceStatus,
  processFlow,
} from "../services/TPApiCall";
import { getVehiclePincode } from "../services/masterServices";
import ReactSelect from "../Tags/ReactSelectProposal";
import { toast } from "react-toastify";
import RedirectionModal from "./RedirectionModal";
import { useNavigate } from "react-router-dom";
import { select } from "react-cookies";
import moment from "moment";

const KYC = ({
  setActiveStep2,
  setCurrentStep,
  setActiveStep3,
  kycStatusCheck,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch,
    clearErrors,
  } = useForm({ mode: "onBlur" });

  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
    reset: reset1,
    setValue: setValue1,
    clearErrors: clearErrors1,
  } = useForm({ mode: "onBlur" });

  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const kycConfig = useSelector((state) => state?.root?.kycConfig);
  const selectedPlan = useSelector((state) => state?.root?.selectedPlan);
  const QuickQouteResult = useSelector(
    (state) => state?.root?.QuickQouteResult
  );
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );
  const [proceedSpinner, setProceedSpinner] = useState(false);
  const [kycMode, setKycMode] = useState("ckyc");
  const [kycStatus, setKycStatus] = useState(false);
  const [pincodeData, setPincodeData] = useState([]);
  const [pincodeError, setPincodeError] = useState(false);
  const [kycFields, setKycFields] = useState("");
  const [address, setAddress] = useState({
    addressLine1: motorRequest?.addressLine1 || "",
    addressLine2: motorRequest?.addressLine2 || "",
    addressLine3: motorRequest?.addressLine3 || "",
    pincode: motorRequest?.pincode || "",
  });
  const [addressErrors, setAddressErrors] = useState({});
  const [redirectionModal, setRedirectionModal] = useState(false);
  const [redirection, setRedirection] = useState(false);

  const [redirectionUrl, setRedirectionUrl] = useState(null);

  const navigate = useNavigate();

  // const kycFields =
  //   kycStatusCheck &&
  //   kycConfig &&
  //   kycConfig?.KycFieldConfig.filter(
  //     (kycType) => kycType.kycType === motorRequest?.kycType?.toLowerCase()
  //   );

  // useEffect(() => {
  //   const dataForFlow = {
  //     ...processFlowObjMotor,
  //     customerLeadId: motorRequest?.customerLeadId,
  //     motorRequest: { ...motorRequest },
  //     processDiscription: "kycAndUserDetails",
  //     step: "step8",
  //     step8: {
  //       ...processFlowObjMotor.step8,
  //       kycAndUserDetails: {
  //         ...processFlowObjMotor.step8.kycAndUserDetails,
  //       },
  //       motorRequest: { ...motorRequest },
  //     },
  //   };
  //   processFlow(dataForFlow);
  // }, []);

  useEffect(() => {
    // const dataForFlow = {
    //   ...processFlowObjMotor,
    //   customerLeadId: motorRequest?.customerLeadId,
    //   motorRequest: { ...motorRequest },
    //   processDiscription: "kycAndUserDetails",
    //   step: "step8",
    //   step8: {
    //     ...processFlowObjMotor.step8,
    //     motorRequest: { ...motorRequest },
    //     QuickQouteResult: { ...QuickQouteResult },
    //     selectedPlan: { ...selectedPlan },
    //     kycConfig: kycConfig,
    //   },
    // };
    // processFlow(dataForFlow);

    if (kycStatusCheck && kycConfig) {
      setKycFields(
        kycConfig?.KycFieldConfig.filter(
          (kycType) => kycType.kycType === motorRequest?.kycType?.toLowerCase()
        )
      );
      dispatchMotorQuote(
        "kycFields",
        kycConfig?.KycFieldConfig.filter(
          (kycType) => kycType.kycType === motorRequest?.kycType?.toLowerCase()
        )
      );
    }
    setValue("docName", motorRequest?.docName);
  }, [kycConfig, kycStatusCheck]);

  const getKycStatus = async () => {
    setProceedSpinner(true);
    const postData = {
      caseId: motorRequest?.caseId,
      vehicleType: motorRequest?.vehicleType,
      insurerId: selectedPlan?.insurerId,
    };
    const response = await getKycServiceStatus(postData);
    console.log("response", response);
    if (response?.status === "success" && response?.data?.kycStatus) {
      const newDataForFlow = {
        ...processFlowObjMotor?.step8?.kycAndUserDetails,
      };
      newDataForFlow.firstName = motorRequest?.firstName;
      console.log(" data.firstName", newDataForFlow);
      const newData = {
        ...newDataForFlow,
        kycStatus: "completed",
        kycType: motorRequest?.kycType,
      };
      console.log("newData", newData);
      dispatchProcessFlowMotor("step8.kycAndUserDetails", newData);
      dispatchMotorQuote("kycStatus", "completed");
      dispatchMotorQuote("kycData", response?.data?.kycData);
      setKycStatus(true);
      toastSuccess("KYC Successful");
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: "kycAndUserDetails",
        step: "step8",
        step8: {
          ...processFlowObjMotor.step8,
          motorRequest: { ...motorRequest },
          kycAndUserDetails: newData,
          kycConfig: kycConfig,
          planDetails: selectedPlan,
        },
      };
      console.log("dataForFlow", dataForFlow);
      processFlow(dataForFlow);
      setProceedSpinner(false);
    } else {
      setProceedSpinner(false);
      toastFailure(
        `${
          response?.data?.errors
            ? response?.data?.errors[0]?.displayMessage
            : "KYC Failed. Please check your details and try again"
        }`
      );
    }
  };

  // useEffect(() => {
  //   if (redirection) {
  //     getKycStatus();
  //   }
  // }, selectedPlan?.insurerId === insurers.Sompo.insurerId]);

  console.log("kycFields", kycFields);

  const handleAddressChange = (e) => {
    debugger;
    const { name, value } = e.target;
    if (name === "addressLine1") {
      setAddressErrors((prev) => ({
        ...prev,
        addressLine1: "",
      }));
    } else if (name === "addressLine2") {
      setAddressErrors((prev) => ({
        ...prev,
        addressLine2: "",
      }));
    }
    //  else if (name === "addressLine3") {
    //   setAddressErrors((prev) => ({
    //     ...prev,
    //     addressLine3: "",
    //   }));
    // }
    console.log("handleAddressChange", value);
    console.log("handleAddressChange", name);
    setAddress({ ...address, [name]: value });
    dispatchMotorQuote(name, value);
  };

  const validateAddressFields = () => {
    // debugger;
    let isValid = true;
    const newErrors = {};

    if (!address.addressLine1) {
      newErrors.addressLine1 = "Address 1 is required";
      isValid = false;
    }
    if (!address.addressLine2) {
      newErrors.addressLine2 = "Address 2 is required";
      isValid = false;
    }
    // if (!address.addressLine3) {
    //   newErrors.addressLine3 = "Address 3 is required";
    //   isValid = false;
    // }

    setAddressErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    console.log("kycConfig", kycConfig);
    console.log("kycConfig", kycFields);
    console.log("kycConfig", kycStatusCheck);
    console.log("kycConfig", getValues());
  }, []);

  const handleNextClick = () => {
    debugger;
    if (motorRequest?.pincode === "") {
      setPincodeError(true);
    }
    if (validateAddressFields()) {
      selectedPlan?.PersonalAccident
        ? (setCurrentStep(2), setActiveStep2(true))
        : (setCurrentStep(3), setActiveStep3(true));
      // setActiveStep2(true);
      // setCurrentStep(2);
      console.log("processFlowObjMotor", processFlowObjMotor);
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: "kycAndUserDetails",
        step: "step8",
        step8: {
          ...processFlowObjMotor.step8,
          kycAndUserDetails: {
            ...processFlowObjMotor.step8.kycAndUserDetails,
            maritalStatus: motorRequest?.maritalStatus,
            addressLine1: motorRequest?.addressLine1,
            addressLine2: motorRequest?.addressLine2,
            addressLine3: motorRequest?.addressLine3,
            cityId: motorRequest?.cityId,
            city: motorRequest?.city,
            stateId: motorRequest?.stateId,
            state: motorRequest?.state,
            pincode: motorRequest?.pincode,
          },
          motorRequest: { ...motorRequest },
          planDetails: selectedPlan,
        },
      };
      console.log("dataForFlow", dataForFlow);
      processFlow(dataForFlow);
    }
  };

  useEffect(() => {
    getVehiclePincode()
      .then((response) => {
        console.log("RESPONSE PINCODE DATA", response);
        if (response.status === true) {
          let i = 0;
          let arr = [];
          let arr1 = [];
          let j = 0;
          while (i < response.data.length) {
            arr.push({
              value: response.data[i].Pin_Code,
              label:
                response.data[i].Pin_Code + " - " + response.data[i].District,
              District: response.data[i].District,
              City: response.data[i].City,
              State: response.data[i].State,
              cityId: response.data[i].cityId,
              stateId: response.data[i].stateId,
            });

            i++;
          }
          // while (j < response.data.length) {
          //   arr1.push({
          //     value: response.data[j].Pin_Code,
          //     label: response.data[j].District,
          //     District: response.data[j].District,
          //     City: response.data[j].City,
          //     State: response.data[j].State,
          //   });

          //   j++;
          // }
          setPincodeData(arr);
          console.log("ARRAY PINCODE DATA", arr);
          // setCityData(arr1);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const submitKycDetails = async (data) => {
    setProceedSpinner(true);
    console.log("KYC DATA", data);
    let newData = { ...motorRequest };

    if (
      selectedPlan?.Api_name === insurers.Digit.name ||
      selectedPlan?.insurerId === insurers.Digit.insurerId
    ) {
      for (let key in data) {
        if (key === "documentNo") {
          dispatchMotorQuote(`docNumber`, data[key]);
          newData[key] = data[key];
        } else {
          dispatchMotorQuote(`${key}`, data[key]);
          newData[key] = data[key];
        }
      }
      const dataForFlow = {
        ...processFlowObjMotor,
        customerLeadId: motorRequest?.customerLeadId,
        motorRequest: { ...motorRequest },
        processDiscription: "kycAndUserDetails",
        step: "step8",
        step8: {
          ...processFlowObjMotor.step8,
          motorRequest: { ...motorRequest },
          kycAndUserDetails: newData,
          kycConfig: kycConfig,
          planDetails: selectedPlan,
        },
      };
      console.log("dataForFlow", dataForFlow);
      processFlow(dataForFlow);
      if (selectedPlan?.PersonalAccident) {
        setCurrentStep(2);
        return;
      } else {
        setCurrentStep(3);
        return;
      }
    }

    let nameForKyc = {
      firstName: "",
      middleName: "",
      lastName: "",
    };

    if (!kycStatus) {
      for (let key in data) {
        dispatchProcessFlowMotor("step", "step8");
        // if (key === "firstName") continue;
        if (data?.hasOwnProperty("lastName")) {
          if (key === "firstName") {
            console.log("data[key]", data[key]);
            const nameParts = data[key]?.split(" ");
            if (nameParts.length > 0) {
              nameForKyc.firstName = nameParts[0];
            }
            if (nameParts.length > 1) {
              console.log(
                "nameForKyc.middleName",
                nameParts,
                nameParts[nameParts.length - 1]
              );
              nameForKyc.middleName = nameParts[nameParts.length - 1];
            }
            if (nameParts.length > 2) {
              nameForKyc.middleName = nameParts[1];
            }
          } else if (key === "lastName") {
            nameForKyc.lastName = data[key];
          }
        } else {
          if (key === "firstName") {
            console.log("data[key]", data[key]);
            const nameParts = data[key]?.split(" ");
            if (nameParts.length > 0) {
              nameForKyc.firstName = nameParts[0];
            }
            if (nameParts.length > 1) {
              nameForKyc.lastName = nameParts[nameParts.length - 1];
            }
            if (nameParts.length > 2) {
              nameForKyc.middleName = nameParts[1];
            }
          }
        }
        console.log("nameForKyc", nameForKyc);
        dispatchMotorQuote(`${key}`, data[key]);
        // for (let key in nameForKyc) {
        //   dispatchMotorQuote(`${key}`, nameForKyc[key]);
        // }
        function capitalizeValue(value) {
          return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
        }

        for (let key in nameForKyc) {
          const capitalizedValue = capitalizeValue(nameForKyc[key]);
          console.log(key, capitalizedValue);
          dispatchMotorQuote(key, capitalizedValue);
        }

        dispatchProcessFlowMotor(`step8.kycAndUserDetails.${key}`, data[key]);
        newData[key] = data[key];
      }

      try {
        const response = await getKyc(newData, selectedPlan, kycFields);
        console.log("kyc response", response);

        if (response?.data?.kycStatus === "success") {
          dispatchProcessFlowMotor("step", "step8");
          let newDataForFlow = { ...data };
          newDataForFlow.firstName = motorRequest?.firstName;
          console.log(" data.firstName", newDataForFlow);
          const newData = {
            ...newDataForFlow,
            kycStatus: "completed",
            kycType: motorRequest?.kycType,
          };
          console.log("newData", newData);
          dispatchProcessFlowMotor("step8.kycAndUserDetails", newData);

          dispatchMotorQuote("kycStatus", "completed");
          dispatchMotorQuote("kycData", response?.data?.kycData);
          toast.success("KYC Successful", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setKycStatus(true);
          const dataForFlow = {
            ...processFlowObjMotor,
            customerLeadId: motorRequest?.customerLeadId,
            motorRequest: { ...motorRequest },
            processDiscription: "kycAndUserDetails",
            step: "step8",
            step8: {
              ...processFlowObjMotor.step8,
              motorRequest: { ...motorRequest },
              kycAndUserDetails: newData,
              kycConfig: kycConfig,
              planDetails: selectedPlan,
            },
          };
          console.log("dataForFlow", dataForFlow);
          processFlow(dataForFlow);
          setProceedSpinner(false);
        } else if (response?.data?.kycStatus === "failure") {
          dispatchProcessFlowMotor("step", "step8");
          let newDataForFlow = { ...data };
          newDataForFlow.firstName = motorRequest?.firstName;
          console.log(" data.firstName", newDataForFlow);
          const newData = {
            ...newDataForFlow,
            kycStatus: "failure",
            kycType: motorRequest?.kycType,
          };
          console.log("newData", newData);
          const dataForFlow = {
            ...processFlowObjMotor,
            customerLeadId: motorRequest?.customerLeadId,
            motorRequest: { ...motorRequest },
            processDiscription: "kycAndUserDetails",
            step: "step8",
            step8: {
              ...processFlowObjMotor.step8,
              motorRequest: { ...motorRequest },
              kycAndUserDetails: newData,
              kycConfig: kycConfig,
              planDetails: selectedPlan,
            },
          };
          console.log("dataForFlow", dataForFlow);
          processFlow(dataForFlow);
          // const data = { ...processFlowObjMotor, kycStatus: "failure" };
          // dispatchProcessFlowMotor("step8.kycAndUserDetails", data);
          dispatchMotorQuote("kycStatus", "failure");
          setRedirectionUrl(response?.data?.redirectionUrl);

          toastFailure(
            `Your KYC has been failed. We are redirecting you to Insurer's Portal`
          );
          if (selectedPlan?.insurerId === insurers.Sompo.insurerId) {
            setTimeout(() => {
              response?.data?.redirectionUrl &&
                window.open(response?.data?.redirectionUrl, "_blank");
            }, [2000]);
            setTimeout(() => {
              setRedirection(true);
            }, [10000]);
            setProceedSpinner(false);

            return;
          } else if (selectedPlan?.insurerId === insurers.Kotak.insurerId) {
            setTimeout(() => {
              response?.data?.redirectionUrl &&
                window.open(response?.data?.redirectionUrl);
              setProceedSpinner(false);
            }, [2000]);

            return;
          }
          toastFailure(
            `${
              response?.data?.errors
                ? response?.data?.errors[0]?.displayMessage
                : "KYC Failed. Please check your details and try again"
            }`
          );
          setProceedSpinner(false);
        } else {
          setProceedSpinner(false);
          toast.error(
            `${
              response?.data?.errors
                ? response?.data?.errors[0]?.displayMessage
                : "KYC Failed. Please check your details and try again"
            }`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        }
      } catch (error) {
        toast.error(
          `${"KYC Failed. Please check your details and try again"}`,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
        console.error("Error occurred:", error);
        setProceedSpinner(false);
      }
    }

    if (kycStatus) {
      setActiveStep2(true);
    }
  };

  const handleSelectPincode = (data) => {
    setPincodeError(false);
    console.log("pincode data", data);
    dispatchMotorQuote("cityId", data?.cityId);
    dispatchMotorQuote("stateId", data?.stateId);
    dispatchMotorQuote("pincode", data?.value);
    dispatchMotorQuote("city", data?.City);
    dispatchMotorQuote("state", data?.State);
    reset({
      city: data?.City,
      state: data?.State,
      docName: getValues("docName"),
    });

    // setValue("pincode", data?.Pin_Code);
  };

  const handleUploadFile = (e) => {
    console.log("e.target.files", e.target);
  };

  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase();
    setValue(e.target.name, value);
  };

  const handleMaritalStatus = (e) => {
    const value = e.target.value;
    console.log("value", value);
    dispatchMotorQuote("maritalStatus", value);
  };

  const handlePreviousPage = () => {
    const newData = {
      customerLeadId: motorRequest?.customerLeadId,
      step: "step7",
      motorRequest: { ...motorRequest },
      processDiscription: "quotation",
      step7: {
        ...processFlowObjMotor.step7,
        motorRequest: { ...motorRequest },
        QuickQouteResult: { ...QuickQouteResult },
      },
    };
    processFlow(newData);
    setTimeout(() => {
      navigate(`/quotelist/${motorRequest?.customerLeadId}`);
    }, [1000]);
  };

  console.log("errors", errors);
  return (
    <form onSubmit={handleSubmit(submitKycDetails)} className="w-100">
      {/* {motorRequest?.kycStatus === "failure" && (
        <div
          className="nav nav-pills mb-3 mt-3 tab_owner_tab"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={() => {
                setKycMode("ckyc");
                dispatchMotorQuote("kycType", "ckyc");
              }}
            >
              CKYC
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              onClick={() => {
                setKycMode("ovd");
                dispatchMotorQuote("kycType", "ovd");
              }}
            >
              OVD
            </button>
          </li>
        </div>
      )} */}
      <div className="onwer-personal-details">
        <div className="row">
          {/* Full Name */}

          <div className="col-md-6 mb-2">
            <div className="onwer-details">
              <div
                className="did-floating-label-content"
                style={{ marginBottom: "0px" }}
              >
                <>
                  <select
                    className="did-floating-input"
                    {...register("docName", {
                      required: "Please Select Document Type to Verify",
                    })}
                    // defaultValue={kycStatusCheck ? motorRequest?.docName : ""}
                    disabled={kycStatus ? true : false}
                  >
                    <option value="">Select Document Type</option>
                    {kycConfig?.KycFieldConfig.map(
                      (item) =>
                        item?.kycType === kycType?.ckyc && (
                          <option
                            value={item?.docName}
                            selected={
                              motorRequest?.docName === item?.docName
                                ? true
                                : false
                            }
                            onClick={() => reset("")}
                          >
                            {kycDocName[item.docName]}
                          </option>
                        )
                    )}
                  </select>
                  <label htmlFor="idxx4" className="did-floating-label">
                    Select Document Type
                    <span className="text-danger">*</span>
                  </label>
                </>
              </div>
              <p className="f-error text-danger">{errors?.docName?.message}</p>
            </div>
          </div>

          {kycConfig &&
            kycFields &&
            kycFields.map(
              (field) =>
                watch("docName") === field?.docName &&
                field?.requiredField?.map((input) => (
                  <div className="col-md-6 mb-3 mt-3 mt-md-0" key={input}>
                    {input === "gender" ? (
                      <div className="did-floating-label-content">
                        <select
                          name={input}
                          className="did-floating-input"
                          id={input}
                          {...register(input, {
                            required: `${input} is required`,
                          })}
                          disabled={
                            motorRequest?.kycStatus === "completed"
                              ? true
                              : false
                          }
                        >
                          <option value="" className="text-capitalize">
                            {input}
                          </option>
                          <option
                            value="M"
                            selected={
                              kycStatusCheck && motorRequest?.gender === "M"
                            }
                          >
                            Male
                          </option>
                          <option
                            value="F"
                            selected={
                              kycStatusCheck && motorRequest?.gender === "F"
                            }
                          >
                            Female
                          </option>
                          <option
                            value="O"
                            selected={
                              kycStatusCheck && motorRequest?.gender === "O"
                            }
                          >
                            Other
                          </option>
                        </select>
                        <label
                          htmlFor={input}
                          className="did-floating-label text-capitalize"
                        >
                          {input}
                          <span className="text-danger">*</span>
                        </label>
                        <p className="f-error text-danger">
                          {errors?.[input]?.message}
                        </p>
                      </div>
                    ) : (
                      <div className="did-floating-label-content">
                        <input
                          type={
                            input === "dob"
                              ? "date"
                              : input === "docLink"
                              ? "file"
                              : "text"
                          }
                          className="did-floating-input"
                          {...register(input, {
                            required: `${input} is required`,
                            pattern:
                              input === "docNumber"
                                ? {
                                    value: new RegExp(field.regexPattern),
                                    message: "Invalid document number format",
                                  }
                                : undefined,
                          })}
                          defaultValue={
                            input === "firstName"
                              ? field?.requiredField?.includes("lastName")
                                ? motorRequest.middleName
                                  ? `${motorRequest.firstName} ${motorRequest.middleName} `
                                  : `${motorRequest.firstName}`
                                : motorRequest.middleName
                                ? `${motorRequest.firstName} ${motorRequest.middleName} ${motorRequest.lastName}`
                                : `${motorRequest.firstName} ${motorRequest.lastName}`
                              : input === "lastName"
                              ? `${motorRequest.lastName}`
                              : input === "dob"
                              ? motorRequest?.dob
                              : input === "docNumber"
                              ? motorRequest?.docNumber
                              : null
                          }
                          // defaultValue={
                          //   motorRequest?.customerType ===
                          //   CUSTOMER_TYPE.INDIVIDUAL
                          //     ? input === "firstName"
                          //       ? motorRequest?.kycData?.fullName
                          //         ? motorRequest.kycData.fullName
                          //         : motorRequest.middleName
                          //         ? `${motorRequest.firstName} ${motorRequest.middleName} `
                          //         : `${motorRequest.firstName}`
                          //       : input === "lastName"
                          //       ? `${motorRequest.lastName}`
                          //       : input === "dob"
                          //       ? motorRequest?.dob
                          //       : input === "docNumber"
                          //       ? motorRequest?.docNumber
                          //       : null
                          //     : input === "firstName"
                          //     ? motorRequest?.kycData?.fullName
                          //       ? motorRequest.kycData.fullName
                          //       : motorRequest.middleName
                          //       ? `${motorRequest.firstName} ${motorRequest.middleName} ${motorRequest.lastName}`
                          //       : `${motorRequest.firstName} ${motorRequest.lastName}`
                          //     : input === "lastName"
                          //     ? `${motorRequest.lastName}`
                          //     : input === "dob"
                          //     ? motorRequest?.dob
                          //     : input === "docNumber"
                          //     ? motorRequest?.docNumber
                          //     : null
                          // }
                          maxLength={20}
                          disabled={
                            motorRequest?.kycStatus === "completed"
                              ? true
                              : false
                          }
                          name={input}
                          onChange={handleInputChange}
                        />
                        <label
                          htmlFor={input}
                          className="did-floating-label text-capitalize"
                        >
                          {input === "docNumber" ? field.label : input}
                          <span className="text-danger">*</span>
                        </label>
                        <p className="f-error text-danger text-capitalize">
                          {errors?.[input]?.message}
                        </p>
                      </div>
                    )}
                  </div>
                ))
            )}

          {selectedPlan?.Api_name === insurers.Digit.name && (
            <>
              {/* <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div
                    className="did-floating-label-content"
                    style={{ marginBottom: "0px" }}
                  >
                    <>
                      <input
                        className="did-floating-input"
                        {...register("fullName", {
                          required: "Please Enter Full Name",
                        })}
                        defaultValue={
                          motorRequest?.middleName
                            ? `${motorRequest?.firstName} ${motorRequest?.middleName} ${motorRequest?.lastName}`
                            : `${motorRequest?.firstName} ${motorRequest?.lastName}`
                        }
                      />
                      <label htmlFor="idxx4" className="did-floating-label">
                        Enter Full Name
                        <span className="text-danger">*</span>
                      </label>
                    </>
                  </div>
                  <p className="f-error text-danger">
                    {errors?.fullName?.message}
                  </p>
                </div>
              </div> */}
              {/* <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div
                    className="did-floating-label-content"
                    style={{ marginBottom: "0px" }}
                  >
                    <>
                      <select
                        className="did-floating-input"
                        {...register("gender", {
                          required: "Please Select Gender",
                        })}
                      >
                        <option value="">Select Gender</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="O">Other</option>
                      </select>
                      <label htmlFor="idxx4" className="did-floating-label">
                        Select Gender
                        <span className="text-danger">*</span>
                      </label>
                    </>
                  </div>
                  <p className="f-error text-danger">
                    {errors?.gender?.message}
                  </p>
                </div>
              </div> */}
            </>
          )}

          {kycConfig?.kycConfig?.kycStep !== "in_proposal" &&
            motorRequest?.kycStatus !== "completed" && (
              <div className="col-md-12">
                <div className="next-btn-btn">
                  <button
                    type="button"
                    className="proceedBtn1"
                    onClick={() => handlePreviousPage()}
                  >
                    Back to Quotation
                  </button>
                  <button
                    type={
                      selectedPlan?.Api_name === insurers.Digit.name
                        ? "button"
                        : redirection
                        ? "button"
                        : "submit"
                    }
                    className="proceedBtn1"
                    // onClick={() => handleNextStep()}
                    onClick={() => {
                      redirection ? getKycStatus() : null;
                    }}
                  >
                    {proceedSpinner ? (
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    ) : redirection ? (
                      "Verify Kyc"
                    ) : (
                      "Complete KYC"
                    )}
                  </button>
                </div>
              </div>
            )}

          {(kycConfig?.kycConfig?.kycStep === "in_proposal" ||
            motorRequest?.kycStatus === "completed") && (
            <>
              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <select
                      name="maritalStatus"
                      className="did-floating-input"
                      id="maritalStatus"
                      // {...register("maritalStatus", {
                      //   required: "Marital Status is required",
                      // })}
                      onChange={handleMaritalStatus}
                    >
                      <option value="">Marital Status</option>
                      <option value="married">Married</option>
                      <option value="unmarried">Unmarried</option>
                    </select>
                    <label htmlFor="idxx4" className="did-floating-label">
                      Marital Status
                      <span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">
                      {errors?.maritalStatus?.message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      className=" did-floating-input"
                      placeholder=""
                      // {...register("addressLine1", {
                      //   required: "Address 1 is required",
                      // })}
                      // value={address.addressLine1}
                      name="addressLine1"
                      onChange={handleAddressChange}
                      defaultValue={motorRequest?.addressLine1}
                      maxLength={30}

                      //   onChange={(e) => handleSelectAddress1(e.target.value)}
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Address 1<span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">
                      {addressErrors.addressLine1}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      className=" did-floating-input"
                      placeholder=""
                      // {...register("addressLine2", {
                      //   required: "Address 2 is required",
                      // })}
                      // onChange={(e) =>
                      //   dispatchMotorQuote("addressLine2", e.target.value)
                      // }
                      name="addressLine2"
                      onChange={handleAddressChange}
                      defaultValue={motorRequest?.addressLine2}
                      maxLength={30}

                      //   onChange={(e) => handleSelectAddress1(e.target.value)}
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Address 2<span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">
                      {addressErrors.addressLine2}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      className=" did-floating-input"
                      placeholder=""
                      // {...register("addressLine3", {
                      //   required: "Address 3 is required",
                      // })}
                      // onChange={(e) =>
                      //   dispatchMotorQuote("addressLine3", e.target.value)
                      // }
                      name="addressLine3"
                      onChange={handleAddressChange}
                      defaultValue={motorRequest?.addressLine3}
                      maxLength={30}

                      //   onChange={(e) => handleSelectAddress1(e.target.value)}
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Address 3 / Landmark
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <p className="f-error text-danger">
                      {addressErrors.addressLine3}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <ReactSelect
                      options={pincodeData}
                      onChange={(val) => {
                        handleSelectPincode(val);
                      }}
                      placeholder="Select area pincode"
                      className="form-select did-floating-input"
                      defaultValue={motorRequest?.pincode}
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Select Pincode
                      <span className="text-danger">*</span>
                    </label>
                    {pincodeError && (
                      <p className="f-error text-danger">Pincode is required</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      type="text"
                      placeholder=""
                      className="did-floating-input"
                      {...register("city", {
                        required: "City is required",
                      })}
                      disabled
                      defaultValue={motorRequest?.city}
                    />
                    <label
                      htmlFor="idxx4"
                      className="did-floating-label uppercase"
                    >
                      Enter City
                      <span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">{addressErrors.city}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-2">
                <div className="onwer-details">
                  <div className="did-floating-label-content">
                    <input
                      list="text"
                      placeholder=""
                      name="State"
                      {...register("state", {
                        required: "State is required",
                      })}
                      defaultValue={motorRequest?.state}
                      disabled
                      //   onChange={(e) => handleSelectState(e.target.value)}
                      className="did-floating-input uppercase"
                    />
                    <label htmlFor="idxx4" className="did-floating-label">
                      Enter State
                      <span className="text-danger">*</span>
                    </label>
                    <p className="f-error text-danger">{addressErrors.state}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="next-btn-btn">
                  <button
                    type="button"
                    className="proceedBtn1"
                    onClick={() => handlePreviousPage()}
                  >
                    Back to Quotation
                  </button>
                  <button
                    type="submit"
                    // onClick={() => {
                    //   setActiveStep2(true);
                    //   // motorRequest?.personalAccident
                    //   //   ? (setCurrentStep(2), setActiveStep2(true))
                    //   //   : setCurrentStep(3),
                    //   //   setActiveStep3(true);
                    //   setCurrentStep(2);
                    // }}
                    onClick={
                      selectedPlan?.Api_name === insurers.Digit.name
                        ? null
                        : handleNextClick
                    }
                    className="proceedBtn1"
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </div>

        <></>
      </div>
      {redirectionModal && (
        <RedirectionModal
          show={redirectionModal}
          setModal={setRedirectionModal}
          url={redirectionUrl}
        />
      )}
    </form>
  );
};

export default KYC;
