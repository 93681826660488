import React from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { dispatchMotorQuote } from "../../store/action/userActions";

const PrevAddonsModal = ({
  prevAddonsModal,
  togglePrevAddonsModal,
  motorQuotation,
  addonsQuesAsk,
  setPremiumBreakupModal,
}) => {
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  console.log("addonsQuesAsk", addonsQuesAsk);
  const handleChangeAddon = (e) => {
    const { name, checked } = e.target;
    console.log("name checked", name, checked);
    dispatchMotorQuote(`${name}`, checked);
  };

  const handlePrevAddon = () => {
    console.log("motorRequest", motorRequest);
    if (
      !motorRequest?.isPrevZeroDepreciationCover ||
      !motorRequest?.isPrevInvoiceCover ||
      !motorRequest?.isPrevEngineProtection ||
      !motorRequest?.isPrevTyreProtection
    ) {
      dispatchMotorQuote("updatedFutureQuotes", true);
      motorQuotation(motorRequest);
    } else {
      setPremiumBreakupModal(true);
    }
    togglePrevAddonsModal();
  };

  return (
    <Modal
      isOpen={prevAddonsModal}
      toggle={togglePrevAddonsModal}
      size="md"
      // centered
      backdrop="static"
    >
      <ModalBody>
        <div className="vehicle_modal">
          <div className="modal-logo">
            <a>
              <img src="/assets/images/insurefastlogos.svg"></img>
            </a>
          </div>
          <div className="modal_heading w-100 mb-4">
            <h4 className="text-start mt-4">
              Do you have these Addons in Previous Policy?
              {/* Share {quotationPage ? "Quotation" : "Payment"} Link{" "} */}
              {/* <FaRegCopy className="mx-1" /> */}
            </h4>
            <div onClick={togglePrevAddonsModal} class="v2_close false">
              <a>
                <img
                  src="/assets/images/delete_icon.svg"
                  title="Close"
                  alt="close"
                />
              </a>
            </div>
          </div>
          <div className="">
            {addonsQuesAsk &&
              addonsQuesAsk.map((ques) => (
                <div className="my-2">
                  <input
                    type="checkbox"
                    id={ques}
                    className="mx-2"
                    name={`${
                      ques === "zeroDepriciationCover"
                        ? "isPrevZeroDepreciationCover"
                        : ques === "invoiceCover"
                        ? "isPrevInvoiceCover"
                        : ques === "engineProtection"
                        ? "isPrevEngineProtection"
                        : ques === "tyreProtection"
                        ? "isPrevTyreProtection"
                        : null
                    }`}
                    defaultChecked
                    onChange={handleChangeAddon}
                  />
                  <label htmlFor={ques}>{`${
                    ques === "zeroDepriciationCover"
                      ? "Zero Depreciation Cover"
                      : ques === "invoiceCover"
                      ? "Invoice Cover"
                      : ques === "engineProtection"
                      ? "Engine Protection"
                      : ques === "tyreProtection"
                      ? "Tyre Protection"
                      : null
                  }`}</label>
                </div>
              ))}

            {/* <div className="my-2">
              <input
                type="checkbox"
                id="prevZeroDep"
                className="mx-2"
                name="isPrevZeroDepreciationCover"
                defaultChecked
                onChange={handleChangeAddon}
              />
              <label htmlFor="prevZeroDep"> Zero Depreciation Cover</label>
            </div>
            <div className="my-2">
              <input
                type="checkbox"
                id="prevInvoiceCover"
                className="mx-2"
                name="isPrevInvoiceCover"
                defaultChecked
                onChange={handleChangeAddon}
              />
              <label htmlFor="prevInvoiceCover"> Invoice Cover</label>
            </div>
            <div className="my-2">
              <input
                type="checkbox"
                id="prevEngineProtection"
                className="mx-2"
                name="isPrevEngineProtection"
                defaultChecked
                onChange={handleChangeAddon}
              />
              <label htmlFor="prevEngineProtection"> Engine Protection</label>
            </div>
            <div className="my-2">
              <input
                type="checkbox"
                id="prevTyreProtection"
                className="mx-2"
                name="isPrevTyreProtection"
                defaultChecked
                onChange={handleChangeAddon}
              />
              <label htmlFor="prevTyreProtection">Tyre Protection</label>
            </div> */}
          </div>
          <div className="d-flex justify-content-center mt-4">
            <button
              type="button"
              className="secondaryButton"
              onClick={handlePrevAddon}
            >
              {!motorRequest?.isPrevZeroDepreciationCover ||
              !motorRequest?.isPrevInvoiceCover ||
              !motorRequest?.isPrevEngineProtection ||
              !motorRequest?.isPrevTyreProtection
                ? "Update"
                : "Continue"}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PrevAddonsModal;
