import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  dispatchMotorQuote,
  dispatchProcessFlowMotor,
} from "../../store/action/userActions";
import { getNomineeRelations, processFlow } from "../services/TPApiCall";
import { useSelector } from "react-redux";

const NomineeDetails = ({
  setCurrentStep,
  setActiveStep3,
  handlePreviousStep,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ mode: "onBlur" });

  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const processFlowObjMotor = useSelector(
    (state) => state?.root?.processFlowObjMotor
  );

  const [relations, setRelations] = useState([]);

  useEffect(() => {
    getRelations();
  }, []);

  useEffect(() => {
    console.log("errors nominee", errors);
    motorRequest?.nomineeRelation &&
      setValue("nomineeRelation", motorRequest?.nomineeRelation);
  }, []);

  const submitNomineeDetails = (data) => {
    console.log("submitNomineeDetails", data);
    for (let key in data) {
      dispatchMotorQuote(`${key}`, data[key]);
      dispatchProcessFlowMotor(`step9.${key}`, data[key]);
    }
    const dataForFlow = {
      ...processFlowObjMotor,
      customerLeadId: motorRequest?.customerLeadId,
      processDiscription: "nomineeDetails",
      step: "step9",
      step9: {
        nomineeDetails: {
          nomineeRelation: data?.nomineeRelation,
          nomineeName: data?.nomineeName,
          nomineeDob: data?.nomineeDob,
        },
        motorRequest: { ...motorRequest },
      },
    };
    processFlow(dataForFlow);
    setCurrentStep(3);
    setActiveStep3(true);
  };

  const getRelations = async () => {
    const response = await getNomineeRelations();
    console.log("getNomineeRelations", response);

    const formattedData = response?.data.map((item) => ({
      relation: item?.relation,
      relationCode: item?.relationCode,
    }));
    console.log("formattedData", formattedData);
    setRelations(formattedData);
  };

  return (
    <form onSubmit={handleSubmit(submitNomineeDetails)} className="w-100">
      <div className="onwer-personal-details row">
        <div className="col-md-6 mb-3">
          <div className="onwer-details">
            <div className="did-floating-label-content">
              <select
                className="form-select"
                placeholder=""
                {...register("nomineeRelation", {
                  required: "Nominee is required",
                })}
                defaultValue={motorRequest?.nomineeRelation}
              >
                <option value="">Select Relationship</option>
                {relations.map((item, index) => (
                  <option
                    key={index}
                    value={item?.relationCode}
                    selected={
                      motorRequest?.nomineeRelation === item?.relationCode
                        ? true
                        : false
                    }
                  >
                    {item?.relation}
                  </option>
                ))}
              </select>
              <label htmlFor="idxx4" className="did-floating-label">
                Nominee Relationship
                <span className="text-danger">*</span>
              </label>
              <p className="f-error text-danger">
                {errors?.nomineeRelation?.message}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="onwer-details">
            <div className="did-floating-label-content">
              <input
                type="text"
                className="did-floating-input"
                placeholder=""
                {...register("nomineeName", {
                  required: "Field is required",
                })}
                defaultValue={motorRequest?.nomineeName}
              />
              <label htmlFor="idxx4" className="did-floating-label">
                Nominee Name
                <span className="text-danger">*</span>
              </label>
              <p className="f-error text-danger">
                {errors?.nomineeName?.message}
              </p>
            </div>
          </div>
        </div>

        <div className="col-md-6 mb-3">
          <div className="onwer-details">
            <div className="did-floating-label-content">
              <input
                type="date"
                className="did-floating-input"
                placeholder=""
                {...register("nomineeDob", {
                  required: "Date of Birth is required",
                })}
                max={moment().subtract("18", "year").format("YYYY-MM-DD")}
                defaultValue={motorRequest?.nomineeDob}
              />
              <label htmlFor="idxx4" className="did-floating-label">
                Nominee DOB
                <span className="text-danger">*</span>
              </label>
              <p className="f-error text-danger">
                {errors?.nomineeDob?.message}
              </p>
            </div>
          </div>
        </div>

        <div className="next-btn-btn">
          <button className="proceedBtn1" onClick={() => handlePreviousStep()}>
            Previous
          </button>

          <button type="submit" className="proceedBtn1">
            Proceed
          </button>
        </div>
      </div>
    </form>
  );
};

export default NomineeDetails;
